var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pivot-management"
  }, [_c('div', {
    staticClass: "pivot-management__inner"
  }, [_c('sygni-container-title', [_vm._v("Manage data structure")]), _c('div', {
    staticClass: "pivot-management__container"
  }, [_c('div', {
    staticClass: "pivot-management__column"
  }, [_c('div', {
    staticClass: "pivot-management__group"
  }, [_c('p', {
    staticClass: "pivot-management__group-label"
  }, [_vm._v("All fields")]), _c('draggable', {
    staticClass: "pivot-management__group-box",
    attrs: {
      "list": _vm.fields,
      "draggable": ".pivot-management__group-item",
      "data-id": "fields",
      "group": {
        name: 'pivot',
        pull: 'clone',
        put: true
      },
      "clone": _vm.handleChange
    },
    on: {
      "add": function add($event) {
        return _vm.handleAddition($event, 'field');
      }
    }
  }, _vm._l(_vm.fields, function (col) {
    return _c('div', {
      key: col.id,
      staticClass: "pivot-management__group-item"
    }, [_c('div', {
      staticClass: "pivot-management__group-item-bar"
    }, [_c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "value": _vm.isColumnUsed(col.id),
        "disabled": true
      }
    }), _c('p', {
      staticClass: "label item-value"
    }, [_vm._v(_vm._s(col.title))]), _c('div', {
      staticClass: "pivot-management__group-item-actions"
    }, [_c('div', {
      staticClass: "pivot-management__group-item-handle"
    })])], 1)]);
  }), 0)], 1)]), _c('div', {
    staticClass: "pivot-management__column"
  }, [_c('div', {
    staticClass: "pivot-management__group"
  }, [_c('p', {
    staticClass: "pivot-management__group-label"
  }, [_vm._v("Rows")]), _c('draggable', {
    staticClass: "pivot-management__group-box",
    attrs: {
      "list": _vm.rows,
      "draggable": ".pivot-management__group-item",
      "handle": ".pivot-management__group-item-handle, .item-value",
      "data-id": "rows",
      "group": {
        name: 'pivot',
        pull: true,
        put: true
      }
    },
    on: {
      "add": function add($event) {
        return _vm.handleAddition($event, 'row');
      }
    }
  }, _vm._l(_vm.rows, function (col) {
    var _col$format;

    return _c('div', {
      key: col.uuid,
      staticClass: "pivot-management__group-item"
    }, [_c('div', {
      staticClass: "pivot-management__group-item-bar"
    }, [_c('p', {
      staticClass: "label item-value"
    }, [_vm._v(_vm._s(col.title) + " "), (col === null || col === void 0 ? void 0 : col.colType) === 'datetime' ? _c('span', [_vm._v("(" + _vm._s(_vm.getColDisplayFormat(col)) + ")")]) : _vm._e()]), _c('div', {
      staticClass: "pivot-management__group-item-actions"
    }, [(col === null || col === void 0 ? void 0 : col.colType) === 'datetime' ? _c('div', {
      staticClass: "pivot-management__group-item-edit",
      on: {
        "click": function click($event) {
          return _vm.openFieldSettings(col);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('EDIT'),
        "alt": ""
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "pivot-management__group-item-handle"
    })])]), (col === null || col === void 0 ? void 0 : col.colType) === 'datetime' && col !== null && col !== void 0 && col.opened ? _c('div', {
      staticClass: "pivot-management__group-item-options"
    }, [_c('sygni-select', {
      attrs: {
        "label": "DISPLAY FORMAT",
        "clearable": false,
        "options": col === null || col === void 0 ? void 0 : (_col$format = col.format) === null || _col$format === void 0 ? void 0 : _col$format.options,
        "value": col.format.value
      },
      on: {
        "input": function input($event) {
          return _vm.setNewColDateType($event, col);
        }
      }
    })], 1) : _vm._e()]);
  }), 0)], 1)]), _c('div', {
    staticClass: "pivot-management__column"
  }, [_c('div', {
    staticClass: "pivot-management__group"
  }, [_c('p', {
    staticClass: "pivot-management__group-label"
  }, [_vm._v("Columns")]), _c('draggable', {
    staticClass: "pivot-management__group-box",
    attrs: {
      "list": _vm.columns,
      "draggable": ".pivot-management__group-item",
      "handle": ".pivot-management__group-item-handle, .item-value",
      "data-id": "columns",
      "group": {
        name: 'pivot',
        pull: true,
        put: true
      }
    },
    on: {
      "add": function add($event) {
        return _vm.handleAddition($event, 'column');
      }
    }
  }, _vm._l(_vm.columns, function (col) {
    var _col$format2;

    return _c('div', {
      key: col.uuid,
      staticClass: "pivot-management__group-item"
    }, [_c('div', {
      staticClass: "pivot-management__group-item-bar"
    }, [_c('p', {
      staticClass: "label item-value"
    }, [_vm._v(_vm._s(col.title) + " "), (col === null || col === void 0 ? void 0 : col.colType) === 'datetime' ? _c('span', [_vm._v("(" + _vm._s(_vm.getColDisplayFormat(col)) + ")")]) : _vm._e()]), _c('div', {
      staticClass: "pivot-management__group-item-actions"
    }, [(col === null || col === void 0 ? void 0 : col.colType) === 'datetime' ? _c('div', {
      staticClass: "pivot-management__group-item-edit",
      on: {
        "click": function click($event) {
          return _vm.openFieldSettings(col);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('EDIT'),
        "alt": ""
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "pivot-management__group-item-handle"
    })])]), (col === null || col === void 0 ? void 0 : col.colType) === 'datetime' && col !== null && col !== void 0 && col.opened ? _c('div', {
      staticClass: "pivot-management__group-item-options"
    }, [_c('sygni-select', {
      attrs: {
        "label": "DISPLAY FORMAT",
        "clearable": false,
        "options": col === null || col === void 0 ? void 0 : (_col$format2 = col.format) === null || _col$format2 === void 0 ? void 0 : _col$format2.options,
        "value": col.format.value
      },
      on: {
        "input": function input($event) {
          return _vm.setNewColDateType($event, col);
        }
      }
    })], 1) : _vm._e()]);
  }), 0)], 1)]), _c('div', {
    staticClass: "pivot-management__column"
  }, [_c('div', {
    staticClass: "pivot-management__group"
  }, [_c('p', {
    staticClass: "pivot-management__group-label"
  }, [_vm._v("Values")]), _c('draggable', {
    staticClass: "pivot-management__group-box",
    attrs: {
      "list": _vm.values,
      "draggable": ".pivot-management__group-item",
      "handle": ".pivot-management__group-item-handle, .item-value",
      "data-id": "values",
      "group": {
        name: 'pivot',
        pull: true,
        put: true
      }
    },
    on: {
      "add": function add($event) {
        return _vm.handleAddition($event, 'value');
      }
    }
  }, _vm._l(_vm.values, function (col) {
    var _col$format3;

    return _c('div', {
      key: col.uuid,
      staticClass: "pivot-management__group-item"
    }, [_c('div', {
      staticClass: "pivot-management__group-item-bar"
    }, [_c('p', {
      staticClass: "label item-value"
    }, [_c('span', {
      staticClass: "aggregation"
    }, [_vm._v(_vm._s(col.colType === 'number' ? 'SUM' : 'COUNT'))]), _c('span', [_vm._v(_vm._s(col.title) + " "), (col === null || col === void 0 ? void 0 : col.colType) === 'datetime' ? _c('span', [_vm._v("(" + _vm._s(_vm.getColDisplayFormat(col)) + ")")]) : _vm._e()])]), _c('div', {
      staticClass: "pivot-management__group-item-actions"
    }, [(col === null || col === void 0 ? void 0 : col.colType) === 'datetime' ? _c('div', {
      staticClass: "pivot-management__group-item-edit",
      on: {
        "click": function click($event) {
          return _vm.openFieldSettings(col);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('EDIT'),
        "alt": ""
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "pivot-management__group-item-handle"
    })])]), (col === null || col === void 0 ? void 0 : col.colType) === 'datetime' && col !== null && col !== void 0 && col.opened ? _c('div', {
      staticClass: "pivot-management__group-item-options"
    }, [_c('sygni-select', {
      attrs: {
        "label": "GROUP BY",
        "clearable": false,
        "options": col === null || col === void 0 ? void 0 : (_col$format3 = col.format) === null || _col$format3 === void 0 ? void 0 : _col$format3.options,
        "value": col.format.value
      },
      on: {
        "input": function input($event) {
          return _vm.setNewColDateType($event, col);
        }
      }
    })], 1) : _vm._e()]);
  }), 0)], 1)])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }