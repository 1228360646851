




import Vue from 'vue'
import Component from 'vue-class-component';
import breakpoints from '@/plugins/breakpoints';
import { Prop, Watch } from 'vue-property-decorator';
import { defaultSchema, PTableColumn, PTableComponentSchema, View, PivotDataSchema } from './types';
import _ from 'lodash';
import PivotManagement from './PivotManagement.vue';
import { create, all } from 'mathjs'
const math = create(all);

@Component({
  methods: {
    handleMouseWheelMethod: _.debounce(function (this: any, e: any, type?: 'horizontal' | 'vertical') {
      if (this.selectedView === 'grid' && this.pivotData?.openedFilter) {
        return
      }
      
      if (e?.wheelDeltaY !== undefined || e?.type === 'wheel') {
        // mouse wheel
        const scrollSpeedY = Math.ceil(Math.abs(e.deltaY) / 15);
        const scrollSpeedX = Math.ceil(Math.abs(e.deltaX) / 15);

        // const xRange: any = { min: 1, max: 3 }
        // const yRange: any = { min: 1, max: null }

        const wheelType = scrollSpeedY >= scrollSpeedX ? 'vertical' : 'horizontal'
        
        if (wheelType === 'vertical' && this.showVerticalScrollbar) {
          // scrolling up
          if (e.deltaY < 0 && ((this) as any).scrollLockY.start && this.scrollPositionY > 0 && this.missingRows === 0) {
            this.scrollPositionY = this.scrollPositionY - scrollSpeedY < 0 ? 0 : this.scrollPositionY - scrollSpeedY;
          }

          // scrolling down
          if (e.deltaY > 0 && this.scrollLockY.end && this.missingRows === 0) {
            this.scrollPositionY = this.scrollPositionY + scrollSpeedY > this.maxScrollPositionY ? this.maxScrollPositionY : this.scrollPositionY + scrollSpeedY;
          }
        }

        if (wheelType === 'horizontal') {
          // scrolling left
          if (e.deltaX < 0 && this.scrollPositionX >= 0 && this.scrollLockX.start) {
            this.scrollPositionX = this.scrollPositionX - scrollSpeedX < 0 ? 0 : this.scrollPositionX - scrollSpeedX;
          }

          // scrolling right
          if (e.deltaX > 0 && this.scrollLockX.end) {
            this.scrollPositionX = this.scrollPositionX + scrollSpeedX > this.maxScrollPositionX ? this.maxScrollPositionX : this.scrollPositionX + scrollSpeedX;
          }
        }
      } else {
        // mousemove
        const multiplierY = (this.numberOfRows / this.$refs.pTableInner.offsetHeight) * 2
        const multiplierX = (this.numberOfCols / this.$refs.pTableInner.offsetWidth) * 2

        if (this.showVerticalScrollbar && type === 'vertical') {
          // scrolling up
          if (e.movementY < 0) {
            this.scrollPositionY = this.scrollPositionY + (e.movementY * multiplierY) < 0 ? 0 : Math.ceil(this.scrollPositionY + (e.movementY * multiplierY))
          }
  
          // scrolling down
          if (e.movementY > 0) {
            this.scrollPositionY = this.scrollPositionY + (e.movementY * multiplierY) > this.maxScrollPositionY ? this.maxScrollPositionY : Math.ceil(this.scrollPositionY + (e.movementY * multiplierY))
          }
        }

        if (type === 'horizontal') {
          // scrolling left
          if (e.movementX < 0) {
            this.scrollPositionX = this.scrollPositionX + (e.movementX * multiplierX) < 0 ? 0 : Math.ceil(this.scrollPositionX + (e.movementX * multiplierX))
          }
  
          // scrolling right
          if (e.movementX > 0) {
            this.scrollPositionX = this.scrollPositionX + (e.movementX * multiplierX) > this.maxScrollPositionX ? this.maxScrollPositionX : Math.ceil(this.scrollPositionX + (e.movementX * multiplierX))
          }
        }
      }
    }, 3),
  }
})

export default class PivotTableUtilities extends Vue {
  @Prop({ default() { return defaultSchema } }) schema: PTableComponentSchema;
  @Prop({ default: [] }) tableData: any[];

  pivotDataSchema: PTableComponentSchema | null = null
  disableBody: boolean = false;
  scrollPositionX: number = 0
  scrollPositionY: number = 0
  breakpoints = breakpoints;
  displayRows: number = 25
  displayCols: number = 4
  scrollSpeed: number = 1
  scrollbarX: number = 0
  scrollbarY: number = 0
  selectedView: View = null
  tableDataItems: any[] = []
  gridTableWidth: number | null = null
  gridTableScrollWidth: number | null = null
  gridTableHeight: number | null = null
  gridTableScrollHeight: number | null = null
  mouseScrollbarPositions: { horizontal: { left: number, right: number }, vertical: { top: number, bottom: number } } = {
    horizontal: { left: 0, right: 0 },
    vertical: { top: 0, bottom: 0 },
  }
  scrollData: any = {
    mouseOffsetLeft: 0,
    mouseAreaWidth: 0,
    mouseOffsetTop: 0,
    mouseAreaHeight: 0,
    scrollbarOffsetX: 0,
    scrollbarOffsetY: 0,
    maximumScrollWidth: 0,
    maximumScrollHeight: 0,
  }

  scrollLockX: { start: boolean, end: boolean } = {
    start: true,
    end: true
  }

  scrollLockY: { start: boolean, end: boolean } = {
    start: true,
    end: true
  }

  scrollDrag: any = {
    isScrolling: false,
    type: 'vertical'
  }

  resize: { isResizing: boolean, column: string } = {
    isResizing: false,
    column: null,
  }

  isSelectingCols: boolean = false
  colSelectionStart: number | 'rowHead' | null = null
  colSelectionEnd: number | 'rowHead' | null = null

  isSelectingRows: boolean = false
  rowSelectionStart: number | 'rowHead' | null = null
  rowSelectionEnd: number | 'rowHead' | null = null

  isSelectingCells: boolean = false
  cellSelectionStart: { rowIndex: number | 'rowHead' | null, colIndex: number | null } = {
    rowIndex: null,
    colIndex: null
  }
  cellSelectionEnd: { rowIndex: number | 'rowHead' | null, colIndex: number | null } = {
    rowIndex: null,
    colIndex: null
  }

  selection: any = {}
  selections: string[] = []

  columnWidths: any[] = []
  reRender: number = 0
  reRenderFilters: number = 0

  pivotData: PivotDataSchema = {
    rows: [],
    columns: [],
    values: [],
    items: [],
    managementMode: false,
    openedFilter: null,
  }

  filterSelections: any = {}

  inRange(value: number, limitations: { min: number | null, max: number | null }) {
    if (limitations?.min && limitations?.max) {
      return value >= limitations.min && value <= limitations.max;
    }

    if (limitations?.min) {
      return value >= limitations.min
    }

    if (limitations?.max) {
      return value <= limitations.max
    }

    return false
  }

  removeFilters() {
    this.filterSelections = {}
    this.allFiltersData.forEach((filter: any) => {
      filter.selectedValues = []
    })
    this.reRenderFilters++
  }

  selectOpenedFilter(filter: any) {
    this.pivotData.openedFilter = filter
  }

  groupByKeys(dataArray: any, keys: any) {
    return dataArray?.reduce((grouped: any, obj: any) => {
      const filteredColumns = _.cloneDeep(this.filteredColumns)
      const key = keys.map((k: any) => {
        const isBoolean = filteredColumns?.find((el: any) => el?.id === k)?.colType === 'boolean';

        if (isBoolean) {
          if (obj[k] === true) {
            return 'true'
          } else if (obj[k] === false) {
            return 'false'
          } else if (obj[k] === null) {
            return 'null'
          }
        }

        return obj[k] || 'UNKNOWN'
      }).join('-||-'); // Use 'UNKNOWN' as a placeholder for empty values
      grouped[key] = grouped[key] || [];
      grouped[key].push(obj);
      return grouped;
    }, {});
  }

  get filteredTableDataItems() {
    let selectedFilters = Object.keys(this.filterSelections) || []
    let items = [...this.tableDataItems]

    if (selectedFilters?.length) {
      selectedFilters.forEach((filter: string) => {
        if (this.filterSelections[filter]?.length) {
          items = items.filter((el: any) => this.filterSelections[filter].includes(el[filter]))
        }
      })
    }
    
    return items
  }

  get allFiltersData() {
    let items = [...this.tableDataItems]
    const filters: any = []

    this.filteredColumns?.forEach((col: PTableColumn) => {
      const uniqueValues: any = [...new Set(items.map((row: any) => row[col.id]))]
      const column: PTableColumn = this.getCol(col.id, true)

      if (column?.colType === 'number') {
        uniqueValues.sort((a: any, b: any) => a - b)
      } else {
        uniqueValues.sort()
      }

      filters.push({ id: col.id, title: col.title, colType: col.colType, format: col?.format || null, withTime: col?.withTime || false, values: uniqueValues, selectedValues: [] })
    })

    return filters;
  }

  get hasFiltersSelected() {
    let result: boolean = false

    Object.keys(this.filterSelections).forEach((key: string) => {
      if (this.filterSelections[key]?.length > 0) {
        result = true
      }
    })

    return result
  }

  get showPivotScrollbar() {
    return !!(this.displayRows < this.pivotData?.items?.length)
  }

  get operatingSystem() {
    let os = 'unknown';
    if (navigator.userAgent.indexOf("Win") != -1) os = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) os = "MacOS";
    if (navigator.userAgent.indexOf("X11") != -1) os = "UNIX";
    if (navigator.userAgent.indexOf("Linux") != -1) os = "Linux";

    return os;
  }

  get showVerticalScrollbar() {
    if (this.displayRows < this.numberOfRows) {
      return true
    }

    return this.gridTableHeight !== null && this.gridTableScrollHeight !== null ? !!(this.gridTableScrollHeight > this.gridTableHeight) : false
  }

  get showHorizontalScrollbar() {
    if (this.selectedView === 'grid') {
      return this.gridTableWidth !== null && this.gridTableScrollWidth !== null ? !!(this.gridTableScrollWidth > this.gridTableWidth) : false
    } else {
      if (this.displayCols < this.numberOfCols) {
        return !!(this.displayCols < this.numberOfCols)
      } else {
        return this.gridTableWidth !== null && this.gridTableScrollWidth !== null ? !!(this.gridTableScrollWidth > this.gridTableWidth) : false
      }
    }
  }

  get allCols() {
    const results: any = []
    this.nestedColumns[Object.keys(this.nestedColumns)?.length]?.forEach((col: any) => {
      if (this.pivotDataValueIds?.length) {
        this.pivotDataValueIds.forEach((id: string) => {
          const selectedColumn = this.getCol(id)
          const item = _.cloneDeep(col)
          item.colspan = 1
          item.level = col.level + 1
          item.columnName = id
          item.colType = selectedColumn?.colType || item.colType
          item.id = `${item.id}-||-${id}`
          results.push(item)
        })
      } else {
        const item = _.cloneDeep(col)
        item.colspan = 1
        results.push(item)
      }
    })

    return results
  }

  get numberOfCols() {
    return this.allCols?.length || 0
  }

  // ------------------------------------------------------
  // Pivot view
  // ------------------------------------------------------

  get selectedRowNames() {
    return this.pivotData.rows.map((el) => {
      const format = el?.format?.value
      const option = el?.format?.options?.find((el: any) => el.value === format)

      if (option?.column) {
        return option?.column?.title || option?.column?.id
      }

      return el.title
    }).join(' | ') || ''
  }

  get pivotDataRowIds() {
    return this.pivotData.rows.map((col: PTableColumn) => {
      if (col?.colType === 'datetime') {
        const option = col?.format?.options?.find((el: any) => el.value === col?.format?.value)

        if (option && (option?.key || option?.column)) {
          return option?.key ? option.key : option?.column?.id
        }
      } 

      return col.id
    })
  }

  get pivotDataColIds() {
    return this.pivotData.columns.map((col: PTableColumn) => {
      if (col?.colType === 'datetime') {
        const option = col?.format?.options?.find((el: any) => el.value === col?.format?.value)

        if (option && (option?.key || option?.column)) {
          return option?.key ? option.key : option?.column?.id
        }
      }

      return col.id
    })
  }

  get pivotDataValueIds() {
    return this.pivotData.values.map((col: PTableColumn) => {
      if (col?.colType === 'datetime') {
        const option = col?.format?.options?.find((el: any) => el.value === col?.format?.value)

        if (option && (option?.key || option?.column)) {
          return option?.key ? option.key : option?.column?.id
        }
      }

      return col.id
    })
  }

  get pivotDataValuesLength() {
    return this.pivotDataValueIds?.length || 1
  }

  get nestedColumns() {
    let results: any = []

    const nestedData: any = {}

    for (let index = this.pivotDataColIds.length; index > 0; index--) {
      nestedData[index] = this.groupByKeys(this.filteredTableDataItems, this.pivotDataColIds.slice(0, index))
    }

    Object.keys(nestedData).forEach((key: string) => {
      Object.keys(nestedData[key]).forEach((k: string) => {
        const recordData: any = {}

        for (let index = parseInt(key); index > 0; index--) {
          recordData[this.pivotDataColIds[index - 1]] = k.split('-||-')[index - 1]
        }

        const column: PTableColumn = this.getCol(this.pivotDataColIds[parseInt(key) - 1])
        const colType = column?.colType

        const formattedKey = k.split('-||-')
        if (formattedKey.length) {
          for (let i = 0; i < formattedKey.length; i++) {
            let col: PTableColumn = this.getCol(this.pivotDataColIds[i], true)

            if (col?.colType === 'number') {
              if (col?.format?.value === 'integer') {
                formattedKey[i] = this.$options.filters.integer(formattedKey[i])
              } else if (col?.format?.value === 'leadingZero') {
                formattedKey[i] = this.$options.filters.leadingZeroDigitFormat(formattedKey[i], 2, true)
              } else if (col?.format?.value === 'advancedFloat') {
                formattedKey[i] = this.$options.filters.numberFormat(formattedKey[i], 4, true)
              }
            }
          }

          k = formattedKey.join('-||-')
        }

        results.push({
          ...recordData,
          id: k,
          colType,
          level: parseInt(key),
        })
      })
    })

    if (results.length) {
      results = results.sort((a: any, b: any) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    }

    return this.groupByKeys(results, ['level'])
  }

  addSummaryRow(results: any, amount: number) {
    const summaryColumns = []
    const lastResultsRow = _.cloneDeep(results[results?.length - 1])
    const columns = lastResultsRow?.map((el: any) => {

      const comparisons: any = {}

      for (let index = 0; index < el.level - 1; index++) {
        let col: PTableColumn = this.getCol(this.pivotDataColIds[index], true)

        // TODO: boolean - done
        if (col?.colType === 'number') {
          comparisons[this.pivotDataColIds[index]] = Number(el[this.pivotDataColIds[index]])
        } else if (col?.colType === 'boolean') {
          let value = el[this.pivotDataColIds[index]]

          if (value === 'true') {
            value = true
          } else if (value === 'false') {
            value = false
          } else if (value === 'null') {
            value = null
          }

          comparisons[this.pivotDataColIds[index]] = value
        } else {
          comparisons[this.pivotDataColIds[index]] = el[this.pivotDataColIds[index]]
        }
      }

      el.level = el.level + 1
      el.isTotal = true
      el.totalValue = this.getColumnAggregation(this.getAggregations(comparisons), el.id)
      el.id = `${el.id}-||-total`
      return el
    })

    for (let index = 0; index < amount; index++) {
      summaryColumns.push(columns[index])
    }

    results.push(summaryColumns)

    return results
  }

  get allSummaryCols() {
    const summaryColumns = []
    const columns = _.cloneDeep(this.pivotAvailableColumns)?.map((el: any) => {
      const comparisons: any = {}

      for (let index = 0; index < el.level - 1; index++) {
        let col: PTableColumn = this.getCol(this.pivotDataColIds[index], true)

        // TODO: boolean - done
        if (col?.colType === 'number') {
          comparisons[this.pivotDataColIds[index]] = Number(el[this.pivotDataColIds[index]])
        } else if (col?.colType === 'boolean') {
          let value = el[this.pivotDataColIds[index]]

          if (value === 'true') {
            value = true
          } else if (value === 'false') {
            value = false
          } else if (value === 'null') {
            value = null
          }

          comparisons[this.pivotDataColIds[index]] = value
        } else {
          comparisons[this.pivotDataColIds[index]] = el[this.pivotDataColIds[index]]
        }
      }

      el.level = el.level + 1
      el.isTotal = true
      el.totalValue = this.getColumnAggregation(this.getAggregations(comparisons), el.id)
      el.id = `${el.id}-||-total`
      return el
    })

    for (let index = 0; index < columns?.length; index++) {
      summaryColumns.push(columns[index])
    }

    return summaryColumns
  }

  get formattedColumnRows() {
    const nested = this.nestedColumns

    if (this.pivotDataColIds?.length) {
      const nestedLength = Object.keys(nested)?.length
      let results: any = []
  
      for (let index = 1; index <= nestedLength; index++) {
        let itemResults: any = []
        let items = nested[index];
  
        for (let n = 0; n < items.length; n++) {
          let item = items[n];
          const colspanLength = nested[nestedLength].filter((el: any) => {
            let check: boolean = true
            for (let i = 0; i < item.level; i++) {
              const comparisonKey: string = this.pivotDataColIds[i]
              const keyValue = item.id.split('_____')[0]
              const value = keyValue.split('-||-')[i]

              let column: PTableColumn = this.getCol(comparisonKey, true)

              if (column?.colType === 'number') {
                if (Number(el[comparisonKey]) !== Number(value)) {
                  check = false
                  break;
                }
              } else {
                if (el[comparisonKey] !== value) {
                  check = false
                  break;
                }
              }
              
            }
            return check
          })?.length || 1

          
          const colspan = (colspanLength * this.pivotDataValuesLength) - 1
          
          itemResults.push({
            ...item,
            colspan: 1
          })

          if (colspan >= 1) {
            for (let x = 0; x < colspan; x++) {
              const clearItem = _.cloneDeep(item)
              clearItem.id = `${clearItem.id}_____${x}`
              clearItem.colspan = 1
              clearItem.abstract = true
              itemResults.push(clearItem)
            }
          }
        }
    
        results.push(itemResults)
      }
      
      if (this.pivotDataValueIds?.length && results[Object.keys(this.nestedColumns).length - 1]) {
        const aggregationResults: any = []
    
        let i: number = 1;
        results[Object.keys(this.nestedColumns).length - 1].forEach((col: any) => {
          const id: string = this.pivotDataValueIds[i - 1]
          const item = _.cloneDeep(col)
          const selectedColumn = this.getCol(id)
          item.colspan = 1
          const keyId = item.id?.split('_____')[0]
          item.id = `${keyId}-||-${id}`
          item.level = item.level + 1
          item.columnName = id
          item.colType = selectedColumn?.colType || item.colType
          delete item?.abstract
          
          aggregationResults.push(item)

          if (i >= this.pivotDataValueIds?.length) {
            i = 1
          } else {
            i++;
          }

        })
    
        results.push(aggregationResults)
      }
      
      results.push(_.cloneDeep(this.allSummaryCols))
      
      const showedResults: any[] = []

      for (let index = 0; index < results.length; index++) {
        const row: any[] = results[index];
        const showedResultRows: any[] = []
        let scrollPosition: number = 0
        let colsUsed: number = 0
        
        for (let i = 0; i < row.length; i++) {
          const col = row[i];

          if (this.scrollPositionX <= (scrollPosition + (col?.colspan || 1))) {
            const maxColSpanSize = ((scrollPosition + (col?.colspan || 1)) - this.scrollPositionX) < (col?.colspan || 1) ? (scrollPosition + (col?.colspan || 1)) - this.scrollPositionX : (col?.colspan || 1)

            if (maxColSpanSize > 0) {
              let colSpan = col?.colspan || 1
              if (maxColSpanSize === (col?.colspan || 1)) {
                if (colsUsed < this.displayCols) {
                  if ((col?.colspan || 1) >= (this.displayCols - colsUsed)) {
                    colSpan = (this.displayCols - colsUsed)
                  } else {
                    colSpan = ((col?.colspan || 1) > (this.displayCols - colsUsed)) ? (this.displayCols - colsUsed) : colSpan
                  }
                  const colCopy = _.cloneDeep(col)
                  colCopy.colspan = colSpan
                  showedResultRows.push(colCopy)
                  colsUsed += colSpan
                }
              } else {
                if (colsUsed < this.displayCols) {
                  if (maxColSpanSize >= (this.displayCols - colsUsed)) {
                    colSpan = (this.displayCols - colsUsed)
                  } else {
                    colSpan = (maxColSpanSize > (this.displayCols - colsUsed)) ? (this.displayCols - colsUsed) : maxColSpanSize
                  }
                  const colCopy = _.cloneDeep(col)
                  colCopy.colspan = colSpan
                  showedResultRows.push(colCopy)
                  colsUsed += colSpan
                }
              }
            }
          }
          
          scrollPosition += (col?.colspan || 1)
        }

        showedResults.push(showedResultRows)
      }
  
      return showedResults
    }

    let results: any[] = [this.pivotColumns.filter((col: PTableColumn) => col.id !== 'rowGroup').map((col: any) => {
      col.columnName = col.id
      return col
    })]

    return results
  }

  get formattedRows() {
    const results: any = []

    const nestedData: any = {}

    if (this.pivotDataRowIds.length) {
      for (let index = this.pivotDataRowIds.length; index > 0; index--) {
        nestedData[index] = this.groupByKeys(this.filteredTableDataItems, this.pivotDataRowIds.slice(0, index))
      }
    } else {
      if (this.pivotDataColIds.length) {
        results.push({
          items: this.filteredTableDataItems,
          id: "All records",
          detailsOpened: false,
          level: 1
        })
      }
    }

    Object.keys(nestedData).forEach((key: string) => {
      Object.keys(nestedData[key]).forEach((k: string) => {
        const recordData: any = {}
        
        for (let index = parseInt(key); index > 0; index--) {
          recordData[this.pivotDataRowIds[index - 1]] = k.split('-||-')[index - 1]
        }

        if (this.pivotDataColIds?.length) {
          recordData.items = this.groupByKeys(nestedData[key][k], this.pivotDataColIds.slice(0, this.pivotDataColIds.length))
        }

        results.push({
          ...recordData,
          id: k,
          detailsOpened: false,
          level: parseInt(key),
        })
      })
    })

    return {
      results
    }
  }

  get filteredColumns() {
    const columns: PTableColumn[] = this.pivotDataSchema?.columns?.filter((col: PTableColumn) => col.show === undefined || col.show === true);
    
    return this.selectedView === 'pivot' ? columns?.filter((col: PTableColumn) => !col.readOnly) : columns
  }

  get columnsAmount() {
    return this.formattedColumnRows[this.formattedColumnRows?.length - 1]?.length || 0
  }

  // eslint-disable-next-line no-unused-vars
  getCol(columnId: string, deepSearch: boolean = false) {
    const columns: PTableColumn[] = this.selectedView === 'pivot' ? [...this.pivotData.columns, ...this.pivotData.rows, ...this.pivotData.values] : this.filteredColumns
    let column = columns?.find((col: any) => {
      if (col?.colType === 'datetime') {
        const option = col?.format?.options?.find((el: any) => el.value === col?.format?.value)
        
        if (option && (option?.key || option?.column)) {
          return option?.key ? option.key === columnId : option?.column?.id === columnId
        }
      }
      
      return col.id === columnId
    })

    if (deepSearch && column?.colType === 'datetime') {
      const format = column?.format?.value
      const option = column?.format?.options?.find((el: any) => el.value === format)

      if (option?.column) {
        column = option?.column
      }
    }

    return column
  }

  refreshPivotData(initial: boolean = false) {
    let col = this.getCol(this.pivotDataRowIds[0], true)

    let sortFunction: any = (a: any, b: any) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    }

    if (col?.colType === 'number') {
      sortFunction = (a: any, b: any) => a - b
    }


    this.pivotData.items = this.formattedRows.results.filter((el: any) => el.level === 1)?.sort((a: any, b: any) => sortFunction(a, b))
    this.scrollPositionY = 0
    this.scrollPositionX = 0
    this.scrollbarY = 0
    this.scrollbarX = 0
    this.setInitialWidths(initial)
    this.selections = []
    this.reRender++
  }

  toggleManagementMode() {
    this.pivotData.managementMode = !this.pivotData.managementMode;
    if (!this.pivotData.managementMode) {
      (this.$refs.pManagementPanel as PivotManagement).saveChanges();
    }
  }

  openFilters(filterId: string) {
    this.pivotData.openedFilter = filterId
  }

  closeFilters() {
    this.pivotData.openedFilter = null
  }

  updatePivotData(data: any) {
    Object.keys(data).forEach((key: string) => {
      (this.pivotData as any)[key] = data[key]
    })
  }

  getAggregations(comparisons: any) {
    const filteredData: any = []

    for (let index = 0; index < this.filteredTableDataItems.length; index++) {
      const tableRow = this.filteredTableDataItems[index];

      if (Object.keys(comparisons).every((key: string) => tableRow[key] === comparisons[key])) {
        filteredData.push(tableRow)
      }
    }

    return filteredData
  }

  getAggregation(filteredData: any, column: any) {
    if (column.colType !== 'number' || column?.readOnly) {
      return filteredData?.length || 0
    } else {
      const sum = (filteredData as Array<any>).reduce((accumulator: any, object: any) => {
        return math.number(math.bignumber(accumulator)) + math.number(math.bignumber(object[column.id]))
      }, 0);

      return this.$options.filters.numberFormat(math.round(sum, 2), 2) || this.$options.filters.numberFormat(0, 2)
    }
  }

  getColumnAggregation(filteredData: any, column: string) {
    const colFilteredData: any = []
    const colComparisons: any = {}

    for (let index = 0; index < this.pivotDataColIds?.length; index++) {
      let col: PTableColumn = this.getCol(this.pivotDataColIds[index], true)

      // TODO: boolean - done
      if (col?.colType === 'number') {
        colComparisons[this.pivotDataColIds[index]] = Number(column.split('-||-')[index])
      } else if (col?.colType === 'boolean') {
        let value: string | boolean = column.split('-||-')[index]

        if (value === 'true') {
          value = true
        } else if (value === 'false') {
          value = false
        } else if (value === 'null') {
          value = null
        }

        colComparisons[this.pivotDataColIds[index]] = value
      } else {
        colComparisons[this.pivotDataColIds[index]] = column.split('-||-')[index]
      }
    }

    for (let i = 0; i < filteredData.length; i++) {
      const tableRow = filteredData[i];

      if (Object.keys(colComparisons).every((key: string) => tableRow[key] === colComparisons[key])) {
        colFilteredData.push(tableRow)
      }
    }
    
    if (colFilteredData.length) {
      const col: any = this.pivotDataSchema?.columns?.find((el: any) => el.id === column.split('-||-')[column.split('-||-')?.length - 1])
      if (col) {
        return this.getAggregation(colFilteredData, col)
      }
    }

    return 0
  }

  setAggregations(itemsToChange: any[] = []) {
    const usedItems: any[] = (!itemsToChange?.length) ? this.pivotData.items : itemsToChange
    const items = new Array(usedItems.length)

    for (let i = 0; i < usedItems.length; ++i) {
      const el = {
        ...usedItems[i],
        aggregations: {}
      }

      const comparisons: any = {}

      for (let index = 0; index < el.level; index++) {
        let col: PTableColumn = this.getCol(this.pivotDataRowIds[index], true)

        // TODO: boolean - done
        if (col?.colType === 'number') {
          comparisons[this.pivotDataRowIds[index]] = Number(el[this.pivotDataRowIds[index]])
        } else if (col?.colType === 'boolean') {
          let value = el[this.pivotDataRowIds[index]]

          if (value === 'true') {
            value = true
          } else if (value === 'false') {
            value = false
          } else if (value === 'null') {
            value = null
          }

          comparisons[this.pivotDataRowIds[index]] = value
        } else {
          comparisons[this.pivotDataRowIds[index]] = el[this.pivotDataRowIds[index]]
        }
      }

      const filteredData = this.getAggregations(comparisons)
      
      this.pivotData?.values?.forEach((col: PTableColumn) => {
        const columnRows = this.nestedColumns[Object.keys(this.nestedColumns)?.length]
        const columnRowsFormatted: any = []

        if (columnRows?.length) {
          for (let n = 0; n < columnRows.length; n++) {
            const el: any = columnRows[n];
            this.pivotDataValueIds.forEach((id: string) => {
              columnRowsFormatted.push(`${el.id}-||-${id}`)
            })
          }
        } else {
          for (let n = 0; n < this.formattedColumnRows[0]?.length; n++) {
            const el: any = this.formattedColumnRows[0][n];
            columnRowsFormatted.push(el.id)
          }
        }
        
        if (this.formattedColumnRows[this.formattedColumnRows?.length - 2]) {
          columnRowsFormatted.forEach((id: string) => {
            el.aggregations[id] = this.getColumnAggregation(filteredData, id)
          })
        } else {
          el.aggregations[col.id] = this.getAggregation(filteredData, col)
        }
      })

      items[i] = el
    }

    if (itemsToChange?.length) {
      return items
    } else {
      this.pivotData.items = items
    }
  }

  get pivotSelectedColumns() {
    return [
      ...this.pivotData?.columns
    ]
  }

  get pivotColumnsData() {
    return this.groupByKeys(this.filteredTableDataItems, this.pivotDataColIds)
  }

  get pivotColumns() {
    let columns: any = [
      { "id": "rowGroup", "title": "", "colType": "text", "show": true },
      ...this.pivotData?.values
    ]
    
    if (this.pivotData?.columns?.length) {
      const additionalColumns: any = this.formattedColumnRows[this.formattedColumnRows?.length - 2]?.map((col: any) => {
        return {
          id: col.id, title: '', colType: 'text', show: true
        }
      })
      columns = [
        { "id": "rowGroup", "title": "", "colType": "text", "show": true },
        ...additionalColumns
      ]
    }


    return columns
  }

  // ------------------------------------------------------
  // Selections
  // ------------------------------------------------------

  isRowSelected(index: any) {
    if (typeof index === 'string' && index?.includes('rowHead_')) {
      return this.selections.find((key: any) => {
        const comparedKey: string = `${key.split('_')[0]}_${key.split('_')[1]}`
        return comparedKey === index
      })
    } else {
      return this.selections.find((key: any) => {
        return index === 'rowHead' ? key.split('_')[0] === index : parseInt(key.split('_')[0]) === index
      })
    }
  }

  isColSelected(index: number) {
    const keyIndex: number = this.selectedView === 'grid' ? 1 : 2
    return this.selections.find((key: any) => {
      if (key.split('_')[keyIndex]) {
        return parseInt(key.split('_')[keyIndex]) === index
      } else {
        return parseInt(key.split('_')[keyIndex - 1]) === index
      }
    })
  }

  // function to update
  getItemValue(index: string, columns: PTableColumn[]) {
    const itemData = index.split('_');
    const rowIndex = parseInt(itemData[0])
    let colIndex = parseInt(itemData[1])

    if (this.selectedView === 'grid') {
      if (this.allRows[rowIndex]) {
        const item = this.allRows[rowIndex][columns[colIndex]?.id]

        if (columns[colIndex]?.colType === 'datetime') {
          return item ? this.$options.filters.dateWithoutTime(item) : ""
        }
    
        return item || ""
      }
    }
    
    if (this.selectedView === 'pivot') {
      if (this.pivotData?.items[rowIndex]) {
        colIndex -= 1

        if (colIndex < 0) {
          const item = this.pivotData?.items[rowIndex]?.id.split('-||-')[this.pivotData?.items[rowIndex]?.level - 1]

          return item || ""
        } else {
          const item = this.pivotData?.items[rowIndex]?.aggregations[columns[colIndex]?.id]
  
          return item || ""
        }
      }
    }

    return ""
  }

  getColWidth(colName: string) {
    const colWidth = this.columnWidths.find((col: any) => col.id === colName)?.width
    return colWidth ? `max-width: ${colWidth}px; min-width: ${colWidth}px; width: ${colWidth}px` : ''
  }

  // cells
  startSelectingCells(e: any, rowIndex: any, colIndex: number, type: 'rowGroup' | null = null) {
    if (type === 'rowGroup') {
      const specialKey: any = this.operatingSystem === 'MacOS' ? e.metaKey : e.altKey;
      if (!specialKey) {
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    }

    if (e.button === 0) {
      this.isSelectingCells = true
      this.cellSelectionStart.rowIndex = rowIndex
      this.cellSelectionStart.colIndex = colIndex
      this.selectCell(e, `${rowIndex}_${colIndex}`)
    }
  }

  stopSelectingCells() {
    this.isSelectingCells = false
  }

  selectCell(e: any, index: string) {
    const specialKey: any = this.operatingSystem === 'MacOS' ? e.metaKey : e.altKey;

    if (this.selections.includes(index)) {
      if (specialKey) {
        const found = this.selections.findIndex((item: any) => item === index)
        this.selections.splice(found, 1)
      } else {
        if (this.selections?.length === 1) {
          this.selections = []
        } else {
          this.selections = [index]
        }
      }
    } else {
      if (specialKey) {
        this.selections.push(index)
      } else {
        this.selections = [index]
      }
    }
    this.reRender++
  }

  selectMultipleCells(e: any, rowIndex: any, colIndex: number) {
    if (this.isSelectingCells && e.button === 0) {
      const specialKey: any = this.operatingSystem === 'MacOS' ? e.metaKey : e.altKey;
      this.cellSelectionEnd.rowIndex = rowIndex
      this.cellSelectionEnd.colIndex = colIndex

      if (!(this.cellSelectionStart.rowIndex === rowIndex && this.cellSelectionStart.colIndex === colIndex) && !specialKey) {
        const startRow = typeof this.cellSelectionStart?.rowIndex === 'string' ? 0 : this.cellSelectionStart?.rowIndex
        const endRow = typeof this.cellSelectionEnd?.rowIndex === 'string' ? 0 : this.cellSelectionEnd?.rowIndex

        const startCol = typeof this.cellSelectionStart?.colIndex === 'string' ? 0 : this.cellSelectionStart?.colIndex
        const endCol = typeof this.cellSelectionEnd?.colIndex === 'string' ? 0 : this.cellSelectionEnd?.colIndex

        let rowEnd = Number(startRow) >= Number(endRow) ? Number(startRow) : Number(endRow)
        let rowStart = Number(startRow) > Number(endRow) ? Number(endRow) : Number(startRow)

        let colEnd = Number(startCol) >= Number(endCol) ? Number(startCol) : Number(endCol)
        let colStart = Number(startCol) > Number(endCol) ? Number(endCol) : Number(startCol)
        
        this.selections = []

        if (this.selectedView === 'grid') {
          if (this.cellSelectionStart?.rowIndex === 'rowHead' || this.cellSelectionEnd?.rowIndex === 'rowHead') {
            for (let colStart = this.cellSelectionStart?.colIndex >= this.cellSelectionEnd?.colIndex ? this.cellSelectionEnd?.colIndex : this.cellSelectionStart?.colIndex; colStart <= colEnd; colStart++) {
              this.selections.push(`rowHead_${colStart}`)
            }
          }
        } else {
          if (typeof this.cellSelectionStart?.rowIndex === 'string' && typeof this.cellSelectionEnd?.rowIndex === 'string') {
            const startRow = Number(this.cellSelectionStart?.rowIndex?.split('_')[1])
            const endRow = Number(this.cellSelectionEnd?.rowIndex?.split('_')[1])

            let rowEnd = Number(startRow) >= Number(endRow) ? Number(startRow) : Number(endRow)
            let rowStart = Number(startRow) > Number(endRow) ? Number(endRow) : Number(startRow)

            for (let i = rowStart; i <= rowEnd; i++) {
              for (let n = colStart; n <= colEnd; n++) {
                this.selections.push(`rowHead_${i}_${n}`)
              }
            }

          } else {
            if (typeof this.cellSelectionStart?.rowIndex === 'string') {
              const rowStart = Number(this.cellSelectionStart?.rowIndex?.split('_')[1])
              for (let i = rowStart; i <= this.formattedColumnRows?.length - 1; i++) {
                for (let n = colStart; n <= colEnd; n++) {
                  this.selections.push(`rowHead_${i}_${n}`)
                }
              }
            } else if (typeof this.cellSelectionEnd?.rowIndex === 'string') {
              const rowEnd = Number(this.cellSelectionEnd?.rowIndex?.split('_')[1])
              for (let i = rowEnd; i <= this.formattedColumnRows?.length - 1; i++) {
                for (let n = colStart; n <= colEnd; n++) {
                  this.selections.push(`rowHead_${i}_${n}`)
                }
              }
            }
          }
        }


        if (!(typeof this.cellSelectionStart?.rowIndex === 'string' && typeof this.cellSelectionEnd?.rowIndex === 'string')) {
          for (let i = rowStart; i <= rowEnd; i++) {
            for (let n = colStart; n <= colEnd; n++) {
              this.selections.push(`${i}_${n}`)
            }
          }
        }
      }
    }
  }

  // cols
  startSelectingCols(e: any, index: number) {
    if (e.button === 0) {
      this.isSelectingCols = true
      this.colSelectionStart = index
      this.selectCol(e, index)
    }
  }

  stopSelectingCols() {
    this.isSelectingCols = false
  }

  selectCol(e: any, index: number) {
    this.selections = []

    if (this.selectedView === 'grid') {
      this.selections.push(`rowHead_${index}`)
      this.filteredTableDataItems.forEach((item: any, i: number) => {
        this.selections.push(`${i}_${index}`)
      })
    }

    if (this.selectedView === 'pivot') {
      for (let n = 0; n < this.formattedColumnRows.length; n++) {
        this.selections.push(`rowHead_${n}_${index}`)
      }

      this.pivotData?.items?.forEach((item: any, i: number) => {
        this.selections.push(`${i}_${index}`)
      })
    }


    for (let i = this.numberOfRows + 2; i < this.missingRows + this.numberOfRows + 2; i++) {
      this.selections.push(`${i}_${index}`)
    }

    this.reRender++
  }

  selectMultipleCols(e: any, index: number | 'rowHead') {
    if (this.isSelectingCols && e.button === 0) {
      this.colSelectionEnd = index
      const colEnd = Number(this.colSelectionStart === 'rowHead' ? 0 : this.colSelectionStart) >= Number(this.colSelectionEnd === 'rowHead' ? 0 : this.colSelectionEnd) ? Number(this.colSelectionStart === 'rowHead' ? 0 : this.colSelectionStart) : Number(this.colSelectionEnd)

      this.selections = []

      if (this.selectedView === 'grid') {
        for (let colStart = Number(this.colSelectionStart === 'rowHead' ? 0 : this.colSelectionStart) >= Number(this.colSelectionEnd === 'rowHead' ? 0 : this.colSelectionEnd) ? Number(this.colSelectionEnd === 'rowHead' ? 0 : this.colSelectionEnd) : Number(this.colSelectionStart === 'rowHead' ? 0 : this.colSelectionStart); colStart <= colEnd; colStart++) {
          this.selections.push(`rowHead_${colStart}`)
        }
        this.filteredTableDataItems.forEach((item: any, i: number) => {
          for (let colStart = Number(this.colSelectionStart === 'rowHead' ? 0 : this.colSelectionStart) >= Number(this.colSelectionEnd === 'rowHead' ? 0 : this.colSelectionEnd) ? Number(this.colSelectionEnd === 'rowHead' ? 0 : this.colSelectionEnd) : Number(this.colSelectionStart === 'rowHead' ? 0 : this.colSelectionStart); colStart <= colEnd; colStart++) {
            this.selections.push(`${i}_${colStart}`)
          }
        })
      }
      
      if (this.selectedView === 'pivot') {
        for (let colStart = Number(this.colSelectionStart) >= Number(this.colSelectionEnd) ? Number(this.colSelectionEnd) : Number(this.colSelectionStart); colStart <= colEnd; colStart++) {
          for (let n = 0; n < this.formattedColumnRows.length; n++) {
            this.selections.push(`rowHead_${n}_${colStart}`)
          }
        }
        this.pivotData?.items.forEach((item: any, i: number) => {
          for (let colStart = Number(this.colSelectionStart) >= Number(this.colSelectionEnd) ? Number(this.colSelectionEnd) : Number(this.colSelectionStart); colStart <= colEnd; colStart++) {
            this.selections.push(`${i}_${colStart}`)
          }
        })
      }

      for (let i = this.numberOfRows + 2; i < this.missingRows + this.numberOfRows + 2; i++) {
        for (let colStart = Number(this.colSelectionStart) >= Number(this.colSelectionEnd) ? Number(this.colSelectionEnd) : Number(this.colSelectionStart); colStart <= colEnd; colStart++) {
          this.selections.push(`${i}_${colStart}`)
        }
      }

      this.reRender++
    }
  }

  get pivotAvailableColumns() {
    return this.pivotData?.columns?.length ? this.allCols : this.formattedColumnRows[0]
  }

  // select all
  selectAll() {
    const items = this.selectedView === 'grid' ? this.filteredTableDataItems : this.pivotData?.items
    const columns = this.selectedView === 'grid' ? this.pivotDataSchema.columns.filter((col: any) => col.show) : this.pivotAvailableColumns

    if (this.selections.length) {
      this.selections = []
    } else {

      if (this.selectedView === 'pivot') {
        for (let i = 0; i < this.formattedColumnRows.length; i++) {
          for (let j = 0; j < columns.length + 1; j++) {
            this.selections.push(`rowHead_${i}_${j}`)
          }
        }
      }

      const rowsAmount: number = this.missingRows > 0 ? items?.length + this.missingRows + 2 : items?.length

      for (let index = 0; index < rowsAmount; index++) {
        if (index === 0 && this.selectedView === 'grid') {
          for (let i = 0; i < columns.length; i++) {
            this.selections.push(`rowHead_${i}`)
          }
        }

        let colLength = this.selectedView === 'pivot' ? columns.length + 1 : columns.length

        for (let i = 0; i < colLength; i++) {
          this.selections.push(`${index}_${i}`)
        }
      }
    }
  }

  // scroll
  get maxScrollPositionY() {
    return (this.numberOfRows < this.displayRows) ? this.displayRows : this.numberOfRows - this.displayRows;
  }

  get maxScrollPositionX() {
    return this.numberOfCols > this.displayCols ? this.numberOfCols - this.displayCols : 0
  }

  // rows
  startSelectingRows(e: any, index: any) {
    if (e.button === 0) {
      this.isSelectingRows = true
      this.rowSelectionStart = index
      this.selectRow(e, index)
    }
  }

  stopSelectingRows() {
    this.isSelectingRows = false
  }

  selectRow(e: any, index: any) {
    const columns = this.selectedView === 'grid' ? this.pivotDataSchema.columns.filter((col: any) => col.show) : this.pivotAvailableColumns
    this.selections = []

    this.selections.push(`${index}_0`)

    columns.forEach((col: PTableColumn, i: number) => {
      this.selections.push(`${index}_${i + 1}`)
    })

    this.reRender++
  }

  selectMultipleRows(e: any, index: any) {
    if (this.isSelectingRows && e.button === 0) {
      const columns = this.selectedView === 'grid' ? this.pivotDataSchema.columns.filter((col: any) => col.show) : this.pivotAvailableColumns
      this.rowSelectionEnd = index

      const rowSelectionStart = typeof this.rowSelectionStart === 'string' && this.rowSelectionStart?.includes('rowHead') ? 0 : this.rowSelectionStart
      const rowSelectionEnd = typeof this.rowSelectionEnd === 'string' && this.rowSelectionEnd?.includes('rowHead') ? 0 : this.rowSelectionEnd

      const rowEnd = Number(rowSelectionStart) >= Number(rowSelectionEnd) ? rowSelectionStart : rowSelectionEnd
      let rowStart = Number(rowSelectionStart) <= Number(rowSelectionEnd) ? rowSelectionStart : rowSelectionEnd
      
      this.selections = []
      
      if (typeof this.rowSelectionStart === 'string' && typeof this.rowSelectionEnd === 'string') {
        const rowSelectionStart = this.rowSelectionStart.split('_')[1]
        const rowSelectionEnd = this.rowSelectionEnd.split('_')[1]
        
        const rowEnd = Number(rowSelectionStart) >= Number(rowSelectionEnd) ? rowSelectionStart : rowSelectionEnd
        let rowStart = Number(rowSelectionStart) <= Number(rowSelectionEnd) ? rowSelectionStart : rowSelectionEnd
        
        for (let n: any = rowStart; n <= rowEnd; n++) {
          this.selections.push(`rowHead_${n}_0`)
          columns.forEach((col: PTableColumn, colIndex: number) => {
            this.selections.push(`rowHead_${n}_${colIndex + 1}`)
          })
        }

      } else {
        if (typeof this.rowSelectionStart === 'string') {
          const rowStart = Number(this.rowSelectionStart?.split('_')[1])
          for (let i = rowStart; i <= this.formattedColumnRows.length; i++) {
            this.selections.push(`rowHead_${i}_0`)
            columns.forEach((col: PTableColumn, colIndex: number) => {
              this.selections.push(`rowHead_${i}_${colIndex + 1}`)
            })
          }
        } else if (typeof this.rowSelectionEnd === 'string') {
          const rowEnd = Number(this.rowSelectionEnd?.split('_')[1])
          for (let i = rowEnd; i <= this.formattedColumnRows.length; i++) {
            this.selections.push(`rowHead_${i}_0`)
            columns.forEach((col: PTableColumn, colIndex: number) => {
              this.selections.push(`rowHead_${i}_${colIndex + 1}`)
            })
          }
        }

        if (this.rowSelectionStart === 'rowHead' || this.rowSelectionEnd === 'rowHead') {
          columns.forEach((col: PTableColumn, i: number) => {
            this.selections.push(`rowHead_${i}`)
          })
        }
  
        for (let n: any = rowStart; n <= rowEnd; n++) {
          this.selections.push(`${n}_0`)
          columns.forEach((col: PTableColumn, i: number) => {
            this.selections.push(`${n}_${i + 1}`)
          })
        }
      }

      this.reRender++
    }
  }

  get numberOfRows() {
    if (this.selectedView === 'pivot') {
      return this.pivotData?.items?.length || 0
    }

    return this.filteredTableDataItems?.length || 0
  }

  get missingRows() {
    return this.numberOfRows < this.displayRows ? this.displayRows - this.numberOfRows : 0
  }

  get allRows() {
    const orderByType = this.pivotDataSchema?.columns.find((col: PTableColumn) => col.id === this.pivotDataSchema?.sorting.orderBy)?.colType || 'text'
    let items: any[] = [...this.filteredTableDataItems]

    if (this.pivotDataSchema?.sorting.orderBy) {
      if (orderByType === 'number') {
        if (this.pivotDataSchema?.sorting?.order === 'desc') {
          items = items?.sort((a: any, b: any) => Number(b[this.pivotDataSchema?.sorting?.orderBy]) - Number(a[this.pivotDataSchema?.sorting?.orderBy]))
        } else {
          items = items?.sort((a: any, b: any) => Number(a[this.pivotDataSchema?.sorting?.orderBy]) - Number(b[this.pivotDataSchema?.sorting?.orderBy]))
        }
      } else {
        items = _.orderBy(items, [this.pivotDataSchema?.sorting.orderBy], [this.pivotDataSchema?.sorting?.order])
      }
    }

    return items
  }

  get rows() {
    return this.allRows?.slice(this.scrollPositionY, this.scrollPositionY + this.displayRows)
  }

  // ------------------------------------------------------
  // Mouse events
  // ------------------------------------------------------

  handleScrollDragMouseDown(type: 'vertical' | 'horizontal', e: any) {
    this.scrollDrag.isScrolling = true
    this.scrollDrag.type = type

    if (type === 'horizontal') {
      this.scrollData.mouseOffsetLeft = (this.$refs.pTableInner as any)?.getBoundingClientRect()?.left;
      this.scrollData.mouseAreaWidth = (this.$refs.pTableInner as any)?.getBoundingClientRect()?.width - (this.$refs.pScrollbarHorizontal as any)?.offsetWidth;
      this.scrollData.scrollbarOffsetX = (this.$refs.pScrollbarHorizontal as any)?.getBoundingClientRect()?.left - this.scrollData.mouseOffsetLeft
      this.scrollData.maximumScrollWidth = (this.$refs.pTableInner as any)?.scrollWidth - (this.$refs.pTableInner as any)?.offsetWidth
  
      const mouseScrollbarPosition = (e.x - this.scrollData.scrollbarOffsetX - this.scrollData.mouseOffsetLeft) >= 0 ? (e.x - this.scrollData.scrollbarOffsetX - this.scrollData.mouseOffsetLeft) <= this.horizontalScrollbarWidth ? (e.x - this.scrollData.scrollbarOffsetX - this.scrollData.mouseOffsetLeft) : this.horizontalScrollbarWidth : 0;
  
      const scrollPosition = {
        left: mouseScrollbarPosition,
        right: (this.$refs.pScrollbarHorizontal as any)?.offsetWidth - mouseScrollbarPosition
      }
  
      this.mouseScrollbarPositions.horizontal = scrollPosition
    } else {
      this.scrollData.mouseOffsetTop = (this.$refs.pTableInner as any)?.getBoundingClientRect()?.top;
      this.scrollData.mouseAreaHeight = (this.$refs.pTableInner as any)?.getBoundingClientRect()?.height - (this.$refs.pScrollbarVertical as any)?.offsetHeight;
      this.scrollData.scrollbarOffsetY = (this.$refs.pScrollbarVertical as any)?.getBoundingClientRect()?.top - this.scrollData.mouseOffsetTop
      this.scrollData.maximumScrollHeight = (this.$refs.pTableInner as any)?.scrollHeight - (this.$refs.pTableInner as any)?.offsetHeight

      const mouseScrollbarPosition = (e.y - this.scrollData.scrollbarOffsetY - this.scrollData.mouseOffsetTop) >= 0 ? (e.y - this.scrollData.scrollbarOffsetY - this.scrollData.mouseOffsetTop) <= (this.verticalScrollbarHeight as number) ? (e.y - this.scrollData.scrollbarOffsetY - this.scrollData.mouseOffsetTop) : (this.verticalScrollbarHeight as number) : 0;

      const scrollPosition = {
        top: mouseScrollbarPosition,
        bottom: (this.$refs.pScrollbarVertical as any)?.offsetHeight - mouseScrollbarPosition
      }

      this.mouseScrollbarPositions.vertical = scrollPosition
    }
  }

  handleScrollDrag(e: any) {
    if (this.selectedView === 'grid' && this.pivotData?.openedFilter) {
      return
    }

    if (this.scrollDrag.isScrolling && this.showVerticalScrollbar) {
      if (this.scrollDrag.type === 'vertical') {
        const mousePosition = (e.y - this.scrollData.mouseOffsetTop - this.mouseScrollbarPositions.vertical.top) >= 0 ? (e.y - this.scrollData.mouseOffsetTop - this.mouseScrollbarPositions.vertical.top) >= this.scrollData.mouseAreaHeight ? this.scrollData.mouseAreaHeight : e.y - this.scrollData.mouseOffsetTop - this.mouseScrollbarPositions.vertical.top : 0;
        let mousePercentage = mousePosition * 100 / this.scrollData.mouseAreaHeight;

        if (this.missingRows === 0) {
          window.requestAnimationFrame(() => {
            const scrollPosition = parseInt((mousePercentage * this.maxScrollPositionY / 100).toFixed(0))
            this.scrollPositionY = scrollPosition
          })
        } else {
          const scrollTopPosition = parseInt((mousePercentage * this.scrollData.maximumScrollHeight / 100).toFixed(0))
          window.requestAnimationFrame(() => {
            this.scrollbarY = scrollTopPosition;
            (this.$refs.pTableInner as any).scrollTop = scrollTopPosition
          })
        }
      }
    }
    
    if (this.scrollDrag.isScrolling && this.showHorizontalScrollbar) {
      if (this.scrollDrag.type === 'horizontal') {
        const mousePosition = (e.x - this.scrollData.mouseOffsetLeft - this.mouseScrollbarPositions.horizontal.left) >= 0 ? (e.x - this.scrollData.mouseOffsetLeft - this.mouseScrollbarPositions.horizontal.left) >= this.scrollData.mouseAreaWidth ? this.scrollData.mouseAreaWidth : e.x - this.scrollData.mouseOffsetLeft - this.mouseScrollbarPositions.horizontal.left : 0;
        let mousePercentage = mousePosition * 100 / this.scrollData.mouseAreaWidth;

        if (this.useHorizontalNativeScrolling) {
          const scrollLeftPosition = mousePercentage * this.scrollData.maximumScrollWidth / 100;
          window.requestAnimationFrame(() => {
            (this.$refs.pTableInner as any).scrollLeft = scrollLeftPosition
            this.scrollbarX = (this.$refs.pTableInner as any).scrollLeft
          })
        } else {
          window.requestAnimationFrame(() => {
            const scrollPosition = parseInt((mousePercentage * this.maxScrollPositionX / 100).toFixed(0))
            this.scrollPositionX = scrollPosition
          })
        }
      }
    }
  }

  get useHorizontalNativeScrolling() {
    return this.selectedView === 'grid' || this.numberOfCols <= this.displayCols
  }

  get useVerticalNativeScrolling() {
    return this.gridTableHeight !== null && this.gridTableScrollHeight !== null ? !!(this.gridTableScrollHeight > this.gridTableHeight) : false
  }

  get horizontalScrollbarWidth () {
    if (!this.useHorizontalNativeScrolling) {
      const colsLength = this.maxScrollPositionX + this.displayCols
      const percentage = Number(((this.displayCols) * 100 / colsLength).toFixed(2))
      const tableInnerWidth = this.gridTableWidth
      const scrollbarWidth = Number((percentage * tableInnerWidth / 100).toFixed(0))
      
      return scrollbarWidth >= 30 ? scrollbarWidth : 30
    }

    const tableWidth =  this.gridTableScrollWidth
    const tableInnerWidth = this.gridTableWidth
    const scrollbarPercentageWidth = (this.gridTableWidth * 100) / tableWidth
    const scrollbarWidth = Number(((scrollbarPercentageWidth * tableInnerWidth) / 100).toFixed(0))

    return scrollbarWidth >= 30 ? scrollbarWidth : 30
  }

  get verticalScrollbarHeight () {
    if (!this.useVerticalNativeScrolling || this.missingRows === 0) {
      const rowsLength = this.maxScrollPositionY + this.displayRows
      const percentage = Number((this.displayRows * 100 / rowsLength).toFixed(2))
      const tableInnerHeight = (this.$refs.pTableInner as any)?.offsetHeight
      const scrollbarHeight = Number((percentage * tableInnerHeight / 100).toFixed(0))

      return scrollbarHeight >= 30 ? scrollbarHeight : 30
    }

    const tableHeight = this.gridTableScrollHeight
    const tableInnerHeight = this.gridTableHeight
    const scrollbarPercentageHeight = (this.gridTableHeight * 100) / tableHeight
    const scrollbarHeight = ((scrollbarPercentageHeight * tableInnerHeight) / 100).toFixed(0)

    return scrollbarHeight
  }

  handleScroll(e: any) {
    // check y beginning
    this.scrollLockY.start = !!(e.target.scrollTop == 0)

    // check y end
    this.scrollLockY.end = !!(Math.ceil(e.target.scrollTop) + e.target.clientHeight >= e.target.scrollHeight)

    // check x beginning
    this.scrollLockX.start = !!(e.target.scrollLeft == 0)
    const scrollWidth = (this.$refs.pTableEl as any)?.offsetWidth
    const offsetWidth = (this.$refs.pTableInner as any)?.offsetWidth

    // check x end
    this.scrollLockX.end = !!(Math.ceil(e.target.scrollLeft) + offsetWidth >= scrollWidth)

    // check horizontal
    this.scrollbarX = e.target.scrollLeft
    // check vertical
    this.scrollbarY = e.target.scrollTop
  }

  handleResizeMouseDown(colName: string) {
    this.resize.isResizing = true
    this.resize.column = colName
    document.querySelector('body').classList.add('resizing');
  }

  handleMouseUp() {
    if (this.resize.isResizing) {
      this.resize.isResizing = false
      this.resize.column = null
      document.querySelector('body').classList.remove('resizing');
      this.refreshScrollbars()
      this.$nextTick(() => {
        this.checkScrollLocks()
      })
    }

    if (this.isSelectingCells) {
      this.stopSelectingCells()
    }

    if (this.scrollDrag.isScrolling) {
      this.scrollDrag.isScrolling = false
    }
  }

  handleResize(e: any) {
    if (this.resize.isResizing) {
      const col = this.columnWidths.find((col: any) => col.id === this.resize.column)

      if (col) {
        col.width = (parseInt(col.width) >= 20) ? col.width + e.movementX : 20;
      }
    }
  }

  handleMouseWheel(e: any, type?: 'horizontal' | 'vertical') {
    (this as any).handleMouseWheelMethod(e, type)
  }

  handleMouseMove(e: any) {
    if (this.resize.isResizing) {
      this.handleResize(e)
    }

    if (this.scrollDrag.isScrolling) {
      this.handleScrollDrag(e)
    }
  }

  // ------------------------------------------------------
  // General
  // ------------------------------------------------------

  refreshScrollbars() {
    this.$nextTick(() => {
      this.gridTableWidth = (this.$refs.pTableInner as any)?.offsetWidth || null
      this.gridTableScrollWidth = (this.$refs.pTableInner as any)?.scrollWidth || null
      this.gridTableHeight = (this.$refs.pTableInner as any)?.offsetHeight || null
      this.gridTableScrollHeight = (this.$refs.pTableInner as any)?.scrollHeight || null
      this.checkScrollLocks()
    })
  }

  changeView(type: View) {
    this.selectedView = type
    this.scrollPositionY = 0
    this.scrollPositionX = 0
    this.scrollbarY = 0
    this.scrollbarX = 0
    this.selections = []
    this.setInitialWidths()
    this.refreshScrollbars()
    this.reRender++
  }

  checkScrollLocks() {
    if (this.selectedView === 'pivot') {
      const target: any = this.$refs.pPivotContainer
      const scrollWidth = (this.$refs.pTableEl as any)?.scrollWidth
      const offsetWidth = (this.$refs.pTableInner as any)?.offsetWidth
      // check y beginning
      this.scrollLockY.start = !!(target?.scrollTop == 0)
  
      // check y end
      this.scrollLockY.end = !!(Math.ceil(target?.scrollTop) + target?.clientHeight >= target?.scrollHeight)

      // check x beginning
      this.scrollLockX.start = !!(target?.scrollLeft == 0)
  
      // check x end
      this.scrollLockX.end = !!(Math.ceil(target?.scrollLeft) + offsetWidth >= scrollWidth)
    }
  }

  handleSort(colName: string) {
    this.$set(this.pivotDataSchema.sorting, 'order', (this.pivotDataSchema?.sorting.order === 'asc') ? 'desc' : 'asc')
    this.$set(this.pivotDataSchema.sorting, 'orderBy', colName)
    this.selection = {}
    this.reRender++
  }

  setDisplayRowsAmount() {
    const el: any = (this.selectedView === 'grid') ? this.$refs.pGridContainer : this.$refs.pPivotContainer
    this.displayRows = Math.ceil(el?.offsetHeight / 30) + 2
  }

  setDisplayColsAmount() {
    const el: any = (this.selectedView === 'grid') ? this.$refs.pGridContainer : this.$refs.pPivotContainer
    this.displayCols = Math.floor((el?.offsetWidth - 200) / 200)
  }

  blockBody() {
    this.disableBody = true;
  }

  unblockBody() {
    this.disableBody = false;
  }

  getValueLabel(columnId: any) {
    const col = this.getCol(columnId)
    let aggregationType = col?.colType === 'number' ? '(SUM)' : '(COUNT)'

    let colTitle = col?.title || col?.id

    if (col?.colType === 'datetime') {
      const format = col?.format?.value
      const option = col?.format?.options?.find((el: any) => el.value === format)

      if (option?.column) {
        colTitle = option?.column?.title || option?.column?.id
      }
    }

    return `${aggregationType} ${colTitle || ''}`
  }

  // function to update
  // eslint-disable-next-line no-unused-vars
  getColLabel(column: any, checkLabel: boolean = true, checkFormat: boolean = true) {
    let key = this.pivotDataColIds[column.level - 1]

    if (column?.columnName && checkLabel) {
      key = column.columnName
      const colTitle: string = this.getCol(key)?.title || column.columnName

      return column.colType === 'number' ? `(SUM) ${colTitle}` : `(COUNT) ${colTitle}`
    }

    if (column.colType === 'datetime') {
      let col: any = this.getCol(key)

      col = col?.format?.options?.find((el: any) => el?.value ===  col?.format?.value)

      if (col?.column) {
        col = col?.column
        const format = col?.format?.value
        
        if (format === false) {
          return this.$options.filters.thousandSeparator(column[key])
        } else if (format === 'integer') {
          return this.$options.filters.integer(column[key])
        } else if (format === 'leadingZero') {
          return this.$options.filters.leadingZeroDigitFormat(column[key], 2, true)
        } else if (format === 'advancedFloat') {
          return this.$options.filters.numberFormat(column[key], 4)
        } else {
          return this.$options.filters.numberFormat(column[key], 2)
        }
      }

      return this.$options.filters.dateWithoutTime(column[key])
    }

    if (column.colType === 'number' && checkFormat) {
      const format: any = this.getCol(key)?.format?.value
      if (format === false) {
        return this.$options.filters.thousandSeparator(column[key])
      } else if (format === 'integer') {
        return this.$options.filters.integer(column[key])
      } else if (format === 'leadingZero') {
        return this.$options.filters.leadingZeroDigitFormat(column[key], 2, true)
      } else if (format === 'advancedFloat') {
        return this.$options.filters.numberFormat(column[key], 4)
      } else {
        return this.$options.filters.numberFormat(column[key], 2)
      }
    }

    return column[key]
  }

  copyHandler() {
    const columns = this.selectedView === 'grid' ? this.pivotDataSchema.columns.filter((col: any) => col.show) : this.pivotAvailableColumns
    let currentHeadRow: number = 0
    let currentRow: number = 0
    let finalString: string = ''
    let headString: string = ''
    let contentString: string = ''

    const rowHeadSelections = this.selections.filter((el: any) => el.split('_')[0] === 'rowHead').sort((a: any, b: any) => {
      const index = this.selectedView === 'grid' ? 0 : 1;
      const aRowIndex = parseInt(a.split('_')[index])
      const bRowIndex = parseInt(b.split('_')[index])

      if (aRowIndex === bRowIndex) {
        const aColIndex = parseInt(a.split('_')[index + 1])
        const bColIndex = parseInt(b.split('_')[index + 1])

        return aColIndex - bColIndex
      }

      return aRowIndex - bRowIndex
    })

    if (rowHeadSelections?.length) {
      rowHeadSelections?.forEach((key: string) => {
        const rowIndex = parseInt(key.split('_')[1])

        if (this.selectedView === 'grid') {
          const value = this.filteredColumns[rowIndex]?.title?.trim()
    
          if (!headString) {
            headString = value
          } else if (headString) {
            headString = `${headString}\u0009${value}`
          }
        } else {
          const colIndex = parseInt(key.split('_')[2]) - 1
          const columnAmount = this.pivotData.columns?.length
          const hasColumns = !!(columnAmount)
          const columns: any[] = hasColumns ? this.pivotData?.values?.length ? this.allCols : this.nestedColumns[String(Object.keys(this.nestedColumns)?.length)] : this.formattedColumnRows[0]
          const hasValues = !!(this.pivotData?.values?.length)

          let colProperty: any = rowIndex === (hasValues ? this.formattedColumnRows?.length - 2 : this.formattedColumnRows?.length - 1) || !hasColumns ? columns[colIndex]?.columnName : this.pivotDataColIds[rowIndex]

          const col = this.getCol(colProperty, true)
          let value = ''

          if (colIndex === -1) {
            if (rowIndex === 0) {
              value = this.selectedRowNames
            } else if (rowIndex + 1 === this.formattedColumnRows?.length) {
              value = 'Totals:'
            }
          }

          if (colProperty) {
            if (colIndex >= 0) {
              if (rowIndex + 1 > columnAmount) {
                if (col?.isTotal) {
                  value = col?.colType === 'number' ? this.$options.filters.numberFormat(col?.totalValue || 0, 2) : this.$options.filters.thousandSeparator(col?.totalValue || 0)
                } else {
                  value = col?.colType === 'number' ? `(SUM) ${col?.title}` : `(COUNT) ${col?.title}`
                }
              } else {
                const formattedColumn = columns[colIndex]
                formattedColumn.level = rowIndex + 1
                formattedColumn.colType = col.colType
                if (col?.format !== undefined) {
                  formattedColumn.format = col?.format
                }
                value = this.getColLabel(formattedColumn, false, false)
              }
            }
          } else if (!colProperty && colIndex !== -1) {
            const col = this.allSummaryCols[colIndex]
            value = this.getColumnSummaryValue(col)
          }

          if (!headString) {
            headString = value
          } else if (headString && currentHeadRow >= rowIndex) {
            headString = `${headString}\u0009${value}`
          } else if (headString && currentHeadRow < rowIndex) {
            headString = `${headString}\u000A${value}`
          }

          currentHeadRow = rowIndex
        }
      })
    }

    // create row head selections line
    const selectionValues = this.selections.filter((el: any) => el.split('_')[0] !== 'rowHead')
    if (headString && selectionValues.length) {
      headString = `${headString}\u000A`
    }

    if (selectionValues?.length) {
      selectionValues.sort((a: any, b: any) => {
        const aRowIndex = parseInt(a.split('_')[0])
        const bRowIndex = parseInt(b.split('_')[0])
        
        if (aRowIndex === bRowIndex) {
          const aColIndex = parseInt(a.split('_')[1])
          const bColIndex = parseInt(b.split('_')[1])
  
          return aColIndex - bColIndex
        }
  
        return aRowIndex - bRowIndex
  
      }).forEach((key: string) => {
        const rowIndex = parseInt(key.split('_')[0])
        const value = String(this.getItemValue(key, columns))?.trim()
  
        if (!contentString) {
          contentString = value
        } else if (contentString && currentRow >= rowIndex ) {
          contentString = `${contentString}\u0009${value}`
        } else if (contentString && currentRow < rowIndex) {
          contentString = `${contentString}\u000A${value}`
        }
  
        currentRow = rowIndex
      })

      finalString = `${headString}${contentString}`
    } else {
      finalString = headString
    }

    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(finalString)

    return finalString
  }

  getColumnSummaryValue(column: any) {
    if (!this.pivotData?.values?.length) {
      return ''
    }

    return column?.colType !== 'number' ? this.$options.filters.thousandSeparator(column?.totalValue || 0) : this.$options.filters.numberFormat(column?.totalValue || 0, 2)
  }

  setInitialWidths(addOffset: boolean = false) {
    const offset = (addOffset) ? 10 : 0
    this.columnWidths = []

    if (this.selectedView === 'grid') {
      this.pivotDataSchema?.columns.forEach((col: PTableColumn) => {
        if (col.show && (this.$refs[`th_${col.id}`] as any)?.length) {
          this.columnWidths.push({
            id: col.id,
            width: (this.$refs[`th_${col.id}`] as any)[0]?.offsetWidth + offset
          })
        } else {
          this.columnWidths.push({
            id: col.id,
            width: 200
          })
        }
      })
      this.reRender++
    }

    if (this.selectedView === 'pivot') {
      this.columnWidths.push({
        id: 'rowGroup',
        width: (this.$refs['th_rowGroup'] as any)?.length ? (this.$refs['th_rowGroup'] as any)[0]?.offsetWidth + 10 : 200,
      })
      this.allCols?.forEach((col: PTableColumn) => {
        if ((this.$refs[`th_${col.id}`] as any)?.length) {
          this.columnWidths.push({
            id: col.id,
            width: (this.$refs[`th_${col.id}`] as any)[0]?.offsetWidth + 10
          })
        } else {
          this.columnWidths.push({
            id: col.id,
            width: 200
          })
        }
      })
    }
  }

  updateWidths() {
    this.$nextTick(() => {
      if (this.selectedView === 'grid') {
        this.pivotDataSchema?.columns.forEach((col: PTableColumn) => {
          if (col.show) {
            const index = this.columnWidths.findIndex((el: any) => el.id === col.id)
            if (index >= 0) {
              this.columnWidths.splice(index, 1, {
                id: col.id,
                width: (this.$refs[`th_${col.id}`] as any)[0]?.offsetWidth
              })
            }
          }
        })
      }

      if (this.selectedView === 'pivot') {
        this.allCols.forEach((col: PTableColumn) => {
          if (col.show) {
            const index = this.columnWidths.findIndex((el: any) => el.id === col.id)
            if (index >= 0) {
              this.columnWidths.splice(index, 1, {
                id: col.id,
                width: this.$refs[`th_${col.id}`] ? (this.$refs[`th_${col.id}`] as any)[0]?.offsetWidth : 200
              })
            }
          }
        })
      }
    })
    this.reRender++
  }

  // ------------------------------------------------------
  // Hooks
  // ------------------------------------------------------

  created() {
    this.selectedView = this.schema?.view?.type
  }

  // ------------------------------------------------------
  // Watchers
  // ------------------------------------------------------

  @Watch('disableBody', { immediate: true }) onDisableBodyChange() {
    if (this.disableBody) {
      document.querySelector('body').classList.add('blocked');
    } else {
      document.querySelector('body').classList.remove('blocked');
    }
  }

  @Watch('scrollDrag.isScrolling', { deep: true, immediate: true }) onScrollDragIsScrollingChange() {
    if (this.scrollDrag.isScrolling) {
      document.querySelector('body').classList.add('drag')
    } else {
      document.querySelector('body').classList.remove('drag')
    }
  }

  @Watch('pivotData.items.length', { immediate: true }) onPivotDataItemsLengthChange() {
    this.refreshScrollbars()
    this.reRender++
  }

  @Watch('pivotData.rows', { immediate: true, deep: true }) onPivotDataRowsChange() {
    this.refreshPivotData()
    this.setAggregations()
    this.refreshScrollbars()
    this.reRender++
  }
  
  @Watch('pivotData.columns', { immediate: true, deep: true }) onPivotDataColumnsChange() {
    this.refreshPivotData()
    this.setAggregations()
    this.refreshScrollbars()
    this.reRender++
  }
  
  @Watch('pivotData.values', { immediate: true, deep: true }) onPivotDataValuesChange() {
    this.refreshPivotData()
    this.setAggregations()
    this.updateWidths()
    this.refreshScrollbars()
    this.reRender++
  }
  
  @Watch('pivotDataSchema.columns', { deep: true, immediate: true }) onColumnsChange() {
    this.updateWidths()
    this.refreshPivotData()
    this.refreshScrollbars()
  }

  @Watch('pivotData.managementMode', { immediate: true }) onManagementModeChange() {
    this.refreshScrollbars()
  }

  @Watch('breakpoints.h') onBreakpointsHeightChange(): void {
    this.setDisplayRowsAmount();
    this.refreshScrollbars();
  }

  @Watch('breakpoints.w') onBreakpointsWidthChange(): void {
    this.updateWidths();
    this.setDisplayColsAmount();
    this.refreshScrollbars();
  }
}
