var render = function () {
  var _vm$filter, _vm$filter$selectedVa, _vm$filterSelectedVal;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['filter-popover', _vm.isFilterUsed ? 'show' : '']
  }, [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Click here to filter by this column',
      expression: "'Click here to filter by this column'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "filter-popover__icon",
    attrs: {
      "id": "filter-".concat(_vm.id)
    },
    on: {
      "mousedown": function mousedown($event) {
        $event.stopPropagation();
      },
      "click": _vm.handleClick
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOTS'),
      "alt": "Filter"
    }
  })]), _c('b-popover', {
    attrs: {
      "noCloseButton": true,
      "variant": "default",
      "delay": 0,
      "placement": "bottom",
      "container": "p-table-inner",
      "triggers": "click blur",
      "noFade": true,
      "title": "",
      "target": "filter-".concat(_vm.id),
      "customClass": "filter-popover__el"
    },
    on: {
      "hidden": _vm.handleClose,
      "show": _vm.handleShow,
      "scroll": function scroll($event) {
        $event.stopPropagation();
      },
      "wheel": function wheel($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    key: _vm.reRender,
    staticClass: "filter-popover__selections",
    on: {
      "scroll": function scroll($event) {
        $event.stopPropagation();
      },
      "wheel": function wheel($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$filter = _vm.filter) === null || _vm$filter === void 0 ? void 0 : (_vm$filter$selectedVa = _vm$filter.selectedValues) === null || _vm$filter$selectedVa === void 0 ? void 0 : _vm$filter$selectedVa.length) + " items selected ")]), _c('sygni-input', {
    staticClass: "text-left borderless",
    attrs: {
      "label": "",
      "placeholder": "Search..."
    },
    on: {
      "scroll": function scroll($event) {
        $event.stopPropagation();
      },
      "wheel": function wheel($event) {
        $event.stopPropagation();
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), (_vm$filterSelectedVal = _vm.filterSelectedValuesOptions) !== null && _vm$filterSelectedVal !== void 0 && _vm$filterSelectedVal.length ? _c('div', {
    staticClass: "filter-popover__items",
    on: {
      "scroll": function scroll($event) {
        $event.stopPropagation();
      },
      "wheel": function wheel($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    key: _vm.reRender,
    staticClass: "filter-popover__item"
  }, [_c('sygni-link-button', {
    staticClass: "gn-primary",
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.toggleAllAction
    }
  }, [_vm._v(_vm._s(_vm.toggleAllOptionsLabel))])], 1), _vm._l(_vm.filterSelectedValuesOptions, function (col) {
    return [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: {
          boundary: 'scrollParent',
          placement: 'bottom'
        },
        expression: "{ boundary: 'scrollParent', placement: 'bottom' }",
        modifiers: {
          "hover": true
        }
      }],
      key: col.id,
      class: ['filter-popover__item', col.disabled ? 'disabled' : ''],
      attrs: {
        "title": _vm.getColValue(col.label, true)
      }
    }, [_c('sygni-checkbox', {
      key: _vm.reRenderValues,
      staticClass: "secondary",
      attrs: {
        "label": _vm.getColValue(col.label, false),
        "value": _vm.isColumnUsed(col.label),
        "removeWatcher": true
      },
      on: {
        "changedValue": function changedValue($event) {
          return _vm.handleChange($event, col.label);
        }
      }
    })], 1)];
  })], 2) : _c('div', {
    staticClass: "filter-popover__items"
  }, [_c('div', {
    staticClass: "filter-popover__item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("No values found")])])]), _c('div', {
    staticClass: "filter-popover__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "filled gn-secondary",
    on: {
      "click": _vm.apply
    }
  }, [_vm._v("Apply")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }