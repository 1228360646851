




































import Vue from 'vue';
import Component from 'vue-class-component'
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { Prop, Watch } from 'vue-property-decorator';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import _ from 'lodash';
// import { BPopover } from 'bootstrap-vue';
// import _ from 'lodash';

Component.registerHooks(['validations'])
@Component({
  components: { SygniCheckbox, SygniInput, SygniLinkButton, SygniRectButton },
})
export default class FilterPopover extends Vue {
  @Prop({ default: null }) openedFilter: any;
  @Prop({ default: null }) id: string | null;
  @Prop({ default: null }) allFiltersData: any;
  @Prop({ default: null }) filteredTableDataItems: any;
  value: any = null
  search: string = null
  reRender: number = 0
  reRenderValues: number = 0
  filterSelections: any = {}
  toggleAll: boolean = false
  isFilterUsed: boolean = false
  originalFilter: any = null
  applyChanges: boolean = false

  apply() {
    this.applyChanges = true
    this.checkToggleAll()
    this.updateFilterSelections()
    this.checkIfFilterIsUsed()
    this.$emit('update', this.openedFilter, _.cloneDeep(this.filterSelections[this.openedFilter]))
    this.search = null
    this.closePopovers()
  }

  checkIfFilterIsUsed() {
    // this.isFilterUsed = this.filter?.selectedValues?.length < this.filter?.values?.length || this.filter?.selectedValues?.length === 0
    this.isFilterUsed = this.filter?.selectedValues?.length > 0
  }

  handleShow() {
    this.originalFilter = _.cloneDeep(this.filter)
    this.checkToggleAll()
    this.checkIfFilterIsUsed()
    this.reRender++
    this.reRenderValues++
  }

  checkToggleAll() {
    let allOptions = this.filterSelectedValuesOptions
    // allOptions = allOptions.filter((el: any) => !el.disabled)
    allOptions = allOptions.map((el: any) => el.value)
    let result = true

    allOptions.forEach((option: any) => {
      if (!result) {
        return
      }

      if (!this.filter.selectedValues.includes(option)) {
        result = false
        return true
      }
    })

    this.toggleAll = result
  }

  toggleAllAction() {
    let allOptions = this.filterSelectedValuesOptions
    // allOptions = allOptions.filter((el: any) => !el.disabled)
    allOptions = allOptions.map((el: any) => el.value)

    if (!this.toggleAll) {
      allOptions.forEach((option: any) => {
        if (!this.filter?.selectedValues?.includes(option)) {
          this.filter.selectedValues.push(option)
        }
      })
    } else {
      // const finalArray = _.clone(this.filter.selectedValues).concat(allOptions)
      // const final: any[] = finalArray.filter((el: any, _: any, arr: any) => {
      //   return arr.filter((el2: any) => el2 === el).length === 1
      // })

      this.filter.selectedValues = []
    }

    this.checkToggleAll()
    this.updateFilterSelections()
    this.checkIfFilterIsUsed()
    this.reRender++
    this.reRenderValues++
  }

  get toggleAllOptionsLabel() {
    return this.toggleAll ? 'Deselect all' : 'Select all'
  }

  get selectedLength() {
    return this.filter?.selectedValues?.length || 0
  }

  get optionsLength() {
    return this.filter?.values?.length || 0
  }

  handleClick() {
    this.$emit('click', this.id)
  }

  closePopovers() {
    this.$root.$emit('bv::hide::popover')
  }

  handleClose() {
    if (!this.applyChanges) {
      this.filter.selectedValues = this.originalFilter.selectedValues
    }
    this.checkToggleAll()
    this.updateFilterSelections()
    this.checkIfFilterIsUsed()
    this.$emit('update', this.openedFilter, _.cloneDeep(this.filterSelections[this.openedFilter]))
    this.search = null
    this.applyChanges = false
  }

  get usedValues() {
    const usedValues = [...new Set(this.filteredTableDataItems.map((row: any) => row[this.openedFilter]))]

    return usedValues
  }

  get filter() {
    return this.allFiltersData?.find((el: any) => el.id === this.id)
  }

  updateFilterSelections() {
    let filterSelections: any = {}

    this.allFiltersData?.forEach((col: any) => {
      if (col.selectedValues?.length) {
        filterSelections[col.id] = col.selectedValues
      }
    })

    this.filterSelections = filterSelections
  }

  get filterSelectedValuesOptions() {
    let options = this.filter?.values.map((el: any) => {
      return { label: el, value: el }
    }) || []

    if (this.filter?.colType === 'number' && this.search) {
      options = options?.filter((el: any) => String(el.value)?.toLowerCase().includes(String(this.search)?.toLowerCase()))
    } else if (this.search) {
      options = options?.filter((el: any) => el.value?.toLowerCase().includes(this.search?.toLowerCase()))
    }

    options = options.map((option: any) => {
      option.disabled = (!this.usedValues.includes(option.value)) ? true : false
      return {...option}
    })

    options = options.sort((a: any, b: any) => a.disabled - b.disabled)

    return options
  }

  handleChange(isSelected: boolean, value: any) {
    this.$nextTick(() => {
      if (isSelected) {
        this.filter.selectedValues.push(value)
      } else {
        const index = this.filter.selectedValues.findIndex((el: any) => el === value)
        this.filter.selectedValues.splice(index, 1)
      }
      this.checkToggleAll()
      this.updateFilterSelections()
      this.checkIfFilterIsUsed()
      this.reRender++
    })
  }

  getColValue(value: string | boolean, showOnlyEntireLabels: boolean = true) {
    let formattedValue = value
    switch (this.filter.colType) {
      case 'datetime':
        if (this.filter?.withTime) {
          formattedValue = this.$options.filters.dateWithTime(value)
        } else {
          formattedValue = this.$options.filters.dateWithoutTime(value)
        }
        break;
      case 'number':
        if (this.filter.format?.value === 'integer') {
          formattedValue = this.$options.filters.integer(value)
        } else if (this.filter.format?.value === 'leadingZero') {
          formattedValue = this.$options.filters.leadingZeroDigitFormat(value, 2)
        } else if (this.filter.format?.value === false) {
          formattedValue = this.$options.filters.thousandSeparator(value)
        } else if (this.filter.format?.value === 'advancedFloat') {
          formattedValue = this.$options.filters.numberFormat(value, 4)
        } else {
          formattedValue = this.$options.filters.numberFormat(value, 2)
        }
        break;
      case 'boolean':
        if (value === false) {
          formattedValue = 'false'
        } else if (value === true) {
          formattedValue = 'true'
        } else if (value === null) {
          formattedValue = 'null'
        }
        break;
      default:
        break;
    }

    if (showOnlyEntireLabels) {
      return (formattedValue as String)?.length > 20 ? formattedValue : ''
    } else {
      return this.$options.filters.trimString(formattedValue, 20)
    }
  }

  isColumnUsed(value: any) {
    if (this.filter?.colType === 'boolean') {
      return this.filter.selectedValues?.find((el: any) => el === value) !== undefined ? true : false
    }

    if (this.filter.selectedValues?.find((el: any) => el === value)) {
      return true
    }

    return false
  }

  mounted() {
    this.checkIfFilterIsUsed()
  }

  @Watch('search') onSeachUpdate() {
    this.checkToggleAll()
  }
}
