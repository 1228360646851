






















































































import Vue from 'vue';
import Component from 'vue-class-component'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { Prop } from 'vue-property-decorator';
import { PTableColumn } from './types';
import draggable from 'vuedraggable';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

Component.registerHooks(['validations'])
@Component({
  components: { SygniContainerTitle, SygniCheckbox, SygniSelect, draggable },
})
export default class PivotManagement extends Vue {
  @Prop({ default: [] }) filteredColumns: PTableColumn[]
  @Prop({ default: null }) pivotData: any

  fields: any[] = []
  rows: any[] = []
  columns: any[] = []
  values: any[] = []

  setNewColDateType(value: string, col: any) {
    col.format.value = value
    this.closeAllFields()
  }

  openFieldSettings(col: any) {
    if (col?.opened) {
      this.closeAllFields()
    } else {
      this.closeAllFields()
      col.opened = !col.opened
    }
  }

  closeAllFields() {
    [...this.rows, ...this.columns, ...this.values].map((el: any) => {
      if (el?.opened !== undefined) {
        el.opened = false
      }
      return el
    })
  }

  handleChange(col: any) {
    const formattedCol = _.cloneDeep(col)
    formattedCol.uuid = uuidv4()

    return formattedCol
  }

  saveChanges() {
    this.$emit('change', {
      rows: this.rows,
      columns: this.columns,
      values: this.values,
    })
  }

  setData(data: any) {
    this.rows = data.rows || []
    this.columns = data.columns || []
    this.values = data.values || []
  }

  isColumnUsed(id: string) {
    if (this.rows.find((col: any) => col.id === id) || this.values.find((col: any) => col.id === id) || this.columns.find((col: any) => col.id === id)) {
      return true
    }

    return false
  }

  getColDisplayFormat(col: any) {
    const format = col?.format?.options?.find((el: any) => el.value === col?.format?.value)?.label

    return format || ''
  }

  get datetimeFieldUsedOptions() {
    const fields = [...this.rows, ...this.columns, ...this.values]
    const usedOptions = _.uniq(fields.map((el: any) => el?.format2?.value))

    return usedOptions
  }

  handleAddition(e: any, type: 'field' | 'row' | 'column' | 'value') {
    // const value = e?.item?.querySelector('.item-value')?.textContent;
    // let isUsed: boolean = false
    switch (type) {
      case 'field':
        this.fields = _.uniqWith(this.fields, (arrVal: any, othVal: any) => arrVal.id === othVal.id)
        break;
      case 'row':
        // this.rows = _.uniqWith(this.rows, (arrVal: any, othVal: any) => arrVal.id === othVal.id)
        // isUsed = this.columns.find((col: any) => col.title === value)
        
        // if (isUsed) {
        //   const index = this.rows.findIndex((col: any) => col.title === value)
        //   this.rows.splice(index, 1)
        // }

        break;
      case 'column':
        // this.columns = _.uniqWith(this.columns, (arrVal: any, othVal: any) => arrVal.id === othVal.id)
        // eslint-disable-next-line no-case-declarations
        // isUsed = this.rows.find((col: any) => col.title === value)

        // if (isUsed) {
        //   const index = this.columns.findIndex((col: any) => col.title === value)
        //   this.columns.splice(index, 1)
        // }
        
        break;
      case 'value':
        // this.values = _.uniqWith(this.values, (arrVal: any, othVal: any) => arrVal.id === othVal.id)
        break;
      default:
        break;
    }
  }

  mounted() {
    this.setData(this.pivotData)

    const fields = _.cloneDeep(this.filteredColumns)
    this.fields = fields
  }
}
