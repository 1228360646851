























































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import breakpoints from '@/plugins/breakpoints';
import { Dictionaries } from '@/modules/genprox/modules/fund/modules/portfolio/store/types';
import moment from 'moment'

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniDatePicker, SygniSquareButton, SygniRectButton, SygniModal },
})
export default class ReportingFormTable extends SygniTable<any> {
  dictionaries!: Dictionaries;
  refresh: any = null;

  breakpoints = breakpoints;
  reports: any[] = [];

  tableItems: any = [
    {
      id: 1,
      name: 'Bank Transactions',
      action: 'bank-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 2,
      name: 'P&L Transactions',
      action: 'p-l-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 3,
      name: 'Portfolio & Capital',
      action: 'portfolio-capital',
      type: 'dateTo',
      period: moment().utc().format('YYYY')
    },
    {
      id: 4,
      name: 'All entries Transactions',
      action: 'all-entries-transactions',
      type: 'dateFrom',
      period: moment().utc().format('YYYY')
    },
    {
      id: 5,
      name: 'Receivables & Payables',
      action: 'receivables-payables',
    },
    {
      id: 6,
      name: 'Fundraising',
      action: 'fundraising-analytics-debt',
    },
    {
      id: 7,
      name: 'Brokers',
      action: 'broker',
    },
    {
      id: 8,
      name: 'Interest',
      action: 'interests'
    }
  ]

  get computedTableFields() {
    const fields: any[] = [
      { key: 'name', sortable: false, class: 'text-left', label: 'Report Name'},
      { key: 'actions', sortable: false, class: 'actions', borderless: true, label: 'Actions'},
    ];

    if (this.hasAccountingProgram) {
      fields.splice(1, 0, { key: 'period', sortable: false, class: 'text-left period-col', label: 'Reporting Period'})
    }

    return fields
  }

  get reportList() {
    return this.reports?.length > 0 ? this.reports : this.tableItems
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasAccountingProgram() {
    return !!(this.activeUserData?.accountingProgram)
  }

  getReportName(type: string) {
    switch(type?.toLowerCase()) {
      case 'bank-transactions':
        return 'Bank Transactions'
      case 'p-l-transactions':
        return 'P&L Transactions'
      case 'portfolio-capital':
        return 'Portfolio & Capital'
      case 'all-entries-transactions':
        return 'All entries Transactions'
      case 'receivables-payables':
        return 'Receivables & Payables'
      case 'fundraising-analytics-debt':
        return 'Fundraising'
      case 'broker':
        return 'Brokers'
      case 'interests':
        return 'Interest'
      default:
        return type
    }
  }

  getReportAdditionalFields(type: string) {
    switch(type?.toLowerCase()) {
      case 'bank-transactions':
      case 'p-l-transactions':
      case 'portfolio-capital':
      case 'all-entries-transactions':
        return {
          type: 'dateFrom',
          period: moment().utc().format('YYYY')
        }
      default:
        return {}
    }
  }

  async mounted() {
    let reports: any[] = await this.$store.dispatch('genprox/getFinancialReportingTypes') || []

    reports = reports?.filter((report: any) => !this.hasAccountingProgram ? report?.type != 'optima' : true)?.sort((a: any) => a?.type == 'optima' ? 1 : 2)?.map((report: any, i: number) => {
      return {
        id: i + 1,
        name: this.getReportName(report?.report),
        action: report?.report,
        ...this.getReportAdditionalFields(report?.report)
      }
    })

    this.reports = reports || []
  }
}

