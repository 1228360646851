var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pivot-table-modal"
  }, [_c('div', {
    staticClass: "pivot-table-modal__bg",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "pivot-table-modal__box"
  }, [_c('div', {
    staticClass: "pivot-table-modal__actions-bar"
  }, [_c('button', {
    staticClass: "pivot-table-modal__action pivot-table-modal__action--close",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "pivot-table-modal__inner"
  }, [_c('div', {
    staticClass: "pivot-table-modal__body"
  }, [_c('div', {
    staticClass: "pivot-table-modal__heading"
  }, [_c('sygni-container-title', [_vm._v(_vm._s(_vm.header))]), _vm.description ? _c('p', {
    staticClass: "pivot-table-modal__desc"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e()], 1), _vm._t("content")], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }