var render = function () {
  var _vm$pivotData, _vm$pivotDataSchema, _vm$pivotDataSchema$t, _vm$filteredTableData, _vm$selections, _vm$pivotDataSchema2, _vm$pivotDataSchema2$, _vm$selections2, _vm$selections3, _vm$pivotData2, _vm$pivotDataSchema3, _vm$pivotDataSchema3$, _vm$pivotDataSchema4, _vm$pivotDataSchema4$, _vm$pivotDataSchema4$2, _vm$pivotDataSchema5, _vm$pivotDataSchema5$, _vm$pivotDataSchema5$2, _vm$pivotDataSchema6, _vm$pivotDataSchema6$, _vm$pivotData3, _vm$pivotData4, _vm$pivotData5, _vm$pivotData6, _vm$pivotDataSchema7, _vm$pivotDataSchema7$, _vm$pivotDataSchema7$2, _vm$pivotDataSchema8, _vm$pivotDataSchema8$, _vm$pivotDataSchema8$2, _vm$filteredTableData2, _vm$pivotData8, _vm$pivotData12, _vm$pivotDataSchema14, _vm$pivotDataSchema15, _vm$pivotDataSchema16;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-table",
    on: {
      "mousemove": _vm.handleMouseMove
    }
  }, [_c('div', {
    ref: "pTableBody",
    class: ['p-table__body', _vm.selectedView === 'pivot' ? 'p-table__body--pivot' : '']
  }, [_c('div', {
    staticClass: "p-table__toolbar-wrapper"
  }, [_c('div', {
    staticClass: "p-table-toolbar"
  }, [_c('div', {
    staticClass: "p-table-toolbar__container"
  }, [!((_vm$pivotData = _vm.pivotData) !== null && _vm$pivotData !== void 0 && _vm$pivotData.managementMode) ? _c('div', {
    staticClass: "p-table-toolbar__group p-table-toolbar__group--left"
  }, [(_vm$pivotDataSchema = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema !== void 0 && (_vm$pivotDataSchema$t = _vm$pivotDataSchema.toolbar) !== null && _vm$pivotDataSchema$t !== void 0 && _vm$pivotDataSchema$t.exportToCSV && (_vm$filteredTableData = _vm.filteredTableDataItems) !== null && _vm$filteredTableData !== void 0 && _vm$filteredTableData.length && _vm.selectedView === 'grid' ? _c('div', {
    staticClass: "p-table-toolbar__btn",
    on: {
      "click": function click($event) {
        return _vm.exportToCSV('grid');
      }
    }
  }, [_vm._v("Export to CSV")]) : _vm._e(), _c('div', {
    staticClass: "p-table-toolbar__btn",
    on: {
      "click": _vm.selectAll
    }
  }, [_vm._v(_vm._s((_vm$selections = _vm.selections) !== null && _vm$selections !== void 0 && _vm$selections.length ? 'Deselect all' : 'Select all'))]), _vm.selectedView === 'grid' ? _c('div', {
    staticClass: "p-table-toolbar__btn",
    on: {
      "click": _vm.removeFilters
    }
  }, [_vm._v("Clear filters")]) : _vm._e(), (_vm$pivotDataSchema2 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema2 !== void 0 && (_vm$pivotDataSchema2$ = _vm$pivotDataSchema2.toolbar) !== null && _vm$pivotDataSchema2$ !== void 0 && _vm$pivotDataSchema2$.displaySelectionDetails ? _c('div', {
    staticClass: "p-table-toolbar__info"
  }, [_vm.reportName ? _c('p', {
    staticClass: "p-table-toolbar__info-label"
  }, [_c('span', [_vm._v(_vm._s(_vm.reportName))])]) : _vm._e(), _c('p', {
    staticClass: "p-table-toolbar__info-label"
  }, [_vm._v(" Selected items: " + _vm._s(((_vm$selections2 = _vm.selections) === null || _vm$selections2 === void 0 ? void 0 : _vm$selections2.length) || 0) + " ")]), (_vm$selections3 = _vm.selections) !== null && _vm$selections3 !== void 0 && _vm$selections3.length ? _c('p', {
    staticClass: "p-table-toolbar__info-label"
  }, [_vm._v(" Click \"" + _vm._s(_vm.operatingSystem === 'MacOS' ? 'cmd' : 'ctrl') + "\" + \"c\" to copy values ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "p-table-toolbar__group p-table-toolbar__group--right"
  }, [!((_vm$pivotData2 = _vm.pivotData) !== null && _vm$pivotData2 !== void 0 && _vm$pivotData2.managementMode) ? _c('sygni-link-button', {
    attrs: {
      "arrowSide": "left"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('goBack');
      }
    }
  }, [_vm._v("Go back to reports list")]) : _vm._e(), _vm.selectedView === 'grid' ? [(_vm$pivotDataSchema3 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema3 !== void 0 && (_vm$pivotDataSchema3$ = _vm$pivotDataSchema3.toolbar) !== null && _vm$pivotDataSchema3$ !== void 0 && _vm$pivotDataSchema3$.manageFields ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Manage fields',
      expression: "'Manage fields'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "p-table-toolbar__btn",
    on: {
      "click": _vm.openFieldsModal
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('SETTINGS'),
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "p-table-toolbar__btns"
  }, [(_vm$pivotDataSchema4 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema4 !== void 0 && (_vm$pivotDataSchema4$ = _vm$pivotDataSchema4.view) !== null && _vm$pivotDataSchema4$ !== void 0 && (_vm$pivotDataSchema4$2 = _vm$pivotDataSchema4$.enabled) !== null && _vm$pivotDataSchema4$2 !== void 0 && _vm$pivotDataSchema4$2.includes('grid') ? _c('div', {
    class: ['p-table-toolbar__btn active'],
    on: {
      "click": function click($event) {
        return _vm.changeView('grid');
      }
    }
  }, [_vm._v("Grid view")]) : _vm._e(), (_vm$pivotDataSchema5 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema5 !== void 0 && (_vm$pivotDataSchema5$ = _vm$pivotDataSchema5.view) !== null && _vm$pivotDataSchema5$ !== void 0 && (_vm$pivotDataSchema5$2 = _vm$pivotDataSchema5$.enabled) !== null && _vm$pivotDataSchema5$2 !== void 0 && _vm$pivotDataSchema5$2.includes('pivot') ? _c('div', {
    class: ['p-table-toolbar__btn'],
    on: {
      "click": function click($event) {
        return _vm.changeView('pivot');
      }
    }
  }, [_vm._v("Pivot view")]) : _vm._e()])] : _vm._e(), _vm.selectedView === 'pivot' ? [(_vm$pivotDataSchema6 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema6 !== void 0 && (_vm$pivotDataSchema6$ = _vm$pivotDataSchema6.toolbar) !== null && _vm$pivotDataSchema6$ !== void 0 && _vm$pivotDataSchema6$.manageFields ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !((_vm$pivotData3 = _vm.pivotData) !== null && _vm$pivotData3 !== void 0 && _vm$pivotData3.managementMode) ? 'Manage data' : '',
      expression: "!pivotData?.managementMode ? 'Manage data' : ''",
      modifiers: {
        "hover": true
      }
    }],
    class: ['p-table-toolbar__btn', (_vm$pivotData4 = _vm.pivotData) !== null && _vm$pivotData4 !== void 0 && _vm$pivotData4.managementMode ? 'gn-primary' : ''],
    on: {
      "click": _vm.toggleManagementMode
    }
  }, [(_vm$pivotData5 = _vm.pivotData) !== null && _vm$pivotData5 !== void 0 && _vm$pivotData5.managementMode ? [_vm._v("Apply")] : [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('SETTINGS'),
      "alt": ""
    }
  })]], 2) : _vm._e(), !((_vm$pivotData6 = _vm.pivotData) !== null && _vm$pivotData6 !== void 0 && _vm$pivotData6.managementMode) ? _c('div', {
    staticClass: "p-table-toolbar__btns"
  }, [(_vm$pivotDataSchema7 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema7 !== void 0 && (_vm$pivotDataSchema7$ = _vm$pivotDataSchema7.view) !== null && _vm$pivotDataSchema7$ !== void 0 && (_vm$pivotDataSchema7$2 = _vm$pivotDataSchema7$.enabled) !== null && _vm$pivotDataSchema7$2 !== void 0 && _vm$pivotDataSchema7$2.includes('grid') ? _c('div', {
    class: ['p-table-toolbar__btn'],
    on: {
      "click": function click($event) {
        return _vm.changeView('grid');
      }
    }
  }, [_vm._v("Grid view")]) : _vm._e(), (_vm$pivotDataSchema8 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema8 !== void 0 && (_vm$pivotDataSchema8$ = _vm$pivotDataSchema8.view) !== null && _vm$pivotDataSchema8$ !== void 0 && (_vm$pivotDataSchema8$2 = _vm$pivotDataSchema8$.enabled) !== null && _vm$pivotDataSchema8$2 !== void 0 && _vm$pivotDataSchema8$2.includes('pivot') ? _c('div', {
    class: ['p-table-toolbar__btn active'],
    on: {
      "click": function click($event) {
        return _vm.changeView('pivot');
      }
    }
  }, [_vm._v("Pivot view")]) : _vm._e()]) : _vm._e()] : _vm._e()], 2)])])]), [_vm.selectedView === 'grid' ? _c('div', {
    ref: "pGridContainer",
    class: ['p-table__container', !_vm.showVerticalScrollbar ? 'no-scrolling-vertical' : '', !_vm.showHorizontalScrollbar ? 'no-scrolling-horizontal' : ''],
    on: {
      "wheel": _vm.handleMouseWheel,
      "mouseover": _vm.blockBody,
      "mouseleave": _vm.unblockBody
    }
  }, [(_vm$filteredTableData2 = _vm.filteredTableDataItems) !== null && _vm$filteredTableData2 !== void 0 && _vm$filteredTableData2.length || _vm.hasFiltersSelected ? [_vm.showVerticalScrollbar ? _c('div', {
    staticClass: "p-table__scrollbar p-table__scrollbar--vertical",
    on: {
      "mousedown": function mousedown($event) {
        return _vm.handleScrollDragMouseDown('vertical', $event);
      }
    }
  }, [_c('div', {
    ref: "pScrollbarVertical",
    staticClass: "p-table__scrollbar-el",
    style: "top: ".concat(_vm.verticalScrollPosition, "; height: ").concat(_vm.verticalScrollbarHeight, "px !important;")
  }, [_c('div', {
    staticClass: "p-table__scrollbar-el-mask"
  })])]) : _vm._e(), _vm.showHorizontalScrollbar ? _c('div', {
    staticClass: "p-table__scrollbar p-table__scrollbar--horizontal",
    on: {
      "mousedown": function mousedown($event) {
        return _vm.handleScrollDragMouseDown('horizontal', $event);
      }
    }
  }, [_c('div', {
    ref: "pScrollbarHorizontal",
    staticClass: "p-table__scrollbar-el",
    style: "left: ".concat(_vm.horizontalScrollPosition, "; width: ").concat(_vm.horizontalScrollbarWidth, "px !important;")
  }, [_c('div', {
    staticClass: "p-table__scrollbar-el-mask"
  })])]) : _vm._e(), _c('div', {
    ref: "pTableInner",
    staticClass: "p-table__inner",
    attrs: {
      "id": "p-table-inner"
    },
    on: {
      "scroll": _vm.handleScroll,
      "mousemove": _vm.handleScrollDrag
    }
  }, [_c('table', {
    ref: "pTableEl",
    staticClass: "p-table__el"
  }, [_c('thead', [_c('tr', {
    staticClass: "p-table__col-numbers"
  }, [_c('th', {
    attrs: {
      "height": "30"
    },
    on: {
      "click": _vm.selectAll
    }
  }), _vm._l(_vm.filteredColumns, function (col, index) {
    var _vm$resize;

    return _c('th', {
      key: "th-".concat(col.id, "_").concat(index + 1),
      ref: "th_".concat(col.id),
      refInFor: true,
      class: [_vm.isColSelected(col.id === 'rowGroup' ? index : index + _vm.scrollPositionX) ? 'selected' : ''],
      style: _vm.getColWidth(col.id),
      attrs: {
        "height": "30"
      },
      on: {
        "mousedown": function mousedown($event) {
          $event.stopPropagation();
          return _vm.startSelectingCols($event, index);
        },
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleCols($event, index);
        },
        "mouseup": _vm.stopSelectingCols
      }
    }, [_c('span', [_vm._v(_vm._s(index + 1))]), _c('span', {
      class: ['p-table__drag-handle', ((_vm$resize = _vm.resize) === null || _vm$resize === void 0 ? void 0 : _vm$resize.column) === col.id ? 'visible' : ''],
      on: {
        "mousedown": function mousedown($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.handleResizeMouseDown(col.id);
        }
      }
    })]);
  })], 2)]), _c('tbody', [_c('tr', {
    staticClass: "p-table__col-headers"
  }, [_c('td', {
    class: ['p-table__row-number', _vm.isRowSelected('rowHead') ? 'selected' : ''],
    on: {
      "mousedown": function mousedown($event) {
        return _vm.startSelectingRows($event, 'rowHead');
      },
      "mouseenter": function mouseenter($event) {
        return _vm.selectMultipleRows($event, 'rowHead');
      },
      "mouseup": _vm.stopSelectingRows
    }
  }, [_vm._v("1")]), _vm._l(_vm.filteredColumns, function (col, colIndex) {
    var _vm$pivotData7, _vm$pivotDataSchema9, _vm$pivotDataSchema9$, _vm$pivotDataSchema10, _vm$pivotDataSchema11, _vm$pivotDataSchema12, _vm$pivotDataSchema13;

    return _c('td', {
      key: "tdr-".concat(col.id),
      class: _vm.selections.includes("rowHead_".concat(colIndex)) ? 'selected' : '',
      style: _vm.getColWidth(col.id),
      attrs: {
        "data-id": "rowHead_".concat(colIndex)
      },
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingCells($event, 'rowHead', colIndex);
        },
        "mouseup": _vm.stopSelectingCells,
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleCells($event, 'rowHead', colIndex);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(col.title))]), (col === null || col === void 0 ? void 0 : col.colType) !== 'array' ? _c('filter-popover', {
      key: _vm.reRenderFilters,
      attrs: {
        "id": col.id,
        "openedFilter": (_vm$pivotData7 = _vm.pivotData) === null || _vm$pivotData7 === void 0 ? void 0 : _vm$pivotData7.openedFilter,
        "allFiltersData": _vm.allFiltersData,
        "filteredTableDataItems": _vm.filteredTableDataItems
      },
      on: {
        "update": _vm.onFilterUpdate,
        "click": _vm.selectOpenedFilter,
        "scroll": function scroll($event) {
          $event.stopPropagation();
        },
        "wheel": function wheel($event) {
          $event.stopPropagation();
        }
      }
    }) : _vm._e(), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: "Click here to sort ".concat(((_vm$pivotDataSchema9 = _vm.pivotDataSchema) === null || _vm$pivotDataSchema9 === void 0 ? void 0 : (_vm$pivotDataSchema9$ = _vm$pivotDataSchema9.sorting) === null || _vm$pivotDataSchema9$ === void 0 ? void 0 : _vm$pivotDataSchema9$.order) === 'asc' ? 'descending' : 'ascending'),
        expression: "`Click here to sort ${pivotDataSchema?.sorting?.order === 'asc' ? 'descending' : 'ascending'}`",
        modifiers: {
          "hover": true
        }
      }],
      class: ['p-table__heading-icon', 'p-table__sorting-icon', ((_vm$pivotDataSchema10 = _vm.pivotDataSchema) === null || _vm$pivotDataSchema10 === void 0 ? void 0 : (_vm$pivotDataSchema11 = _vm$pivotDataSchema10.sorting) === null || _vm$pivotDataSchema11 === void 0 ? void 0 : _vm$pivotDataSchema11.orderBy) === col.id ? 'show' : '', ((_vm$pivotDataSchema12 = _vm.pivotDataSchema) === null || _vm$pivotDataSchema12 === void 0 ? void 0 : (_vm$pivotDataSchema13 = _vm$pivotDataSchema12.sorting) === null || _vm$pivotDataSchema13 === void 0 ? void 0 : _vm$pivotDataSchema13.order) === 'desc' ? 'reverse' : ''],
      on: {
        "mousedown": function mousedown($event) {
          $event.stopPropagation();
        },
        "click": function click($event) {
          return _vm.handleSort(col.id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('ARROW UP'),
        "alt": "Sort"
      }
    })])], 1);
  })], 2), _vm._l(_vm.rows, function (row, rowIndex) {
    return _c('tr', {
      key: "".concat(row.reportNumber, "-").concat(_vm.scrollPositionY + rowIndex + 2, "-").concat(_vm.reRender)
    }, [_c('td', {
      class: ['p-table__row-number', _vm.isRowSelected(_vm.scrollPositionY + rowIndex) ? 'selected' : ''],
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingRows($event, _vm.scrollPositionY + rowIndex);
        },
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleRows($event, _vm.scrollPositionY + rowIndex);
        },
        "mouseup": _vm.stopSelectingRows
      }
    }, [_vm._v(_vm._s(_vm.scrollPositionY + rowIndex + 2))]), _vm._l(_vm.filteredColumns, function (col, colIndex) {
      return _c('td', {
        key: "".concat(row.reportNumber, "_").concat(col.id),
        class: _vm.selections.includes("".concat(_vm.scrollPositionY + rowIndex, "_").concat(colIndex)) ? 'selected' : '',
        style: _vm.getColWidth(col.id),
        attrs: {
          "data-id": "".concat(_vm.scrollPositionY + rowIndex, "_").concat(colIndex)
        },
        on: {
          "mousedown": function mousedown($event) {
            return _vm.startSelectingCells($event, _vm.scrollPositionY + rowIndex, colIndex);
          },
          "mouseup": _vm.stopSelectingCells,
          "mouseenter": function mouseenter($event) {
            return _vm.selectMultipleCells($event, _vm.scrollPositionY + rowIndex, colIndex);
          }
        }
      }, [_c('div', {
        class: [_vm.getCellClassList(col), 'cell-overflow']
      }, [_vm._v(_vm._s(_vm.displayCellValue(col.id, row)))])]);
    })], 2);
  }), _vm._l(_vm.missingRows, function (i) {
    var _vm$rows, _vm$rows4;

    return _c('tr', {
      key: "missing-row-".concat(i)
    }, [_c('td', {
      class: ['p-table__row-number', _vm.isRowSelected(_vm.scrollPositionY + i + ((_vm$rows = _vm.rows) === null || _vm$rows === void 0 ? void 0 : _vm$rows.length) + 1) ? 'selected' : ''],
      on: {
        "mousedown": function mousedown($event) {
          var _vm$rows2;

          return _vm.startSelectingRows($event, _vm.scrollPositionY + i + ((_vm$rows2 = _vm.rows) === null || _vm$rows2 === void 0 ? void 0 : _vm$rows2.length) + 1);
        },
        "mouseenter": function mouseenter($event) {
          var _vm$rows3;

          return _vm.selectMultipleRows($event, _vm.scrollPositionY + i + ((_vm$rows3 = _vm.rows) === null || _vm$rows3 === void 0 ? void 0 : _vm$rows3.length) + 1);
        },
        "mouseup": _vm.stopSelectingRows
      }
    }, [_vm._v(_vm._s(i + ((_vm$rows4 = _vm.rows) === null || _vm$rows4 === void 0 ? void 0 : _vm$rows4.length) + _vm.scrollPositionY + 1))]), _vm._l(_vm.filteredColumns, function (col, colIndex) {
      var _vm$rows5, _vm$rows6;

      return _c('td', {
        key: "missing_".concat(col.id),
        class: _vm.selections.includes("".concat(_vm.scrollPositionY + i + ((_vm$rows5 = _vm.rows) === null || _vm$rows5 === void 0 ? void 0 : _vm$rows5.length) + 1, "_").concat(colIndex)) ? 'selected' : '',
        style: _vm.getColWidth(col.id),
        attrs: {
          "data-id": "".concat(_vm.scrollPositionY + i + ((_vm$rows6 = _vm.rows) === null || _vm$rows6 === void 0 ? void 0 : _vm$rows6.length) + 1, "_").concat(colIndex)
        },
        on: {
          "mousedown": function mousedown($event) {
            var _vm$rows7;

            return _vm.startSelectingCells($event, _vm.scrollPositionY + i + ((_vm$rows7 = _vm.rows) === null || _vm$rows7 === void 0 ? void 0 : _vm$rows7.length) + 1, colIndex);
          },
          "mouseup": _vm.stopSelectingCells,
          "mouseenter": function mouseenter($event) {
            var _vm$rows8;

            return _vm.selectMultipleCells($event, _vm.scrollPositionY + i + ((_vm$rows8 = _vm.rows) === null || _vm$rows8 === void 0 ? void 0 : _vm$rows8.length) + 1, colIndex);
          }
        }
      });
    })], 2);
  })], 2)])])] : [_c('div', {
    ref: "pTableInner",
    staticClass: "p-table__inner"
  }, [_c('div', {
    staticClass: "p-table__placeholder"
  }, [_c('div', {
    staticClass: "p-table__placeholder-item"
  }, [_c('div', {
    staticClass: "p-table__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('WWW'),
      "alt": ""
    }
  })]), _c('p', {
    staticClass: "p-table__placeholder-text"
  }, [_vm._v("No data to display")])])])])]], 2) : _vm._e(), _vm.selectedView === 'pivot' && !((_vm$pivotData8 = _vm.pivotData) !== null && _vm$pivotData8 !== void 0 && _vm$pivotData8.managementMode) ? _c('div', {
    ref: "pPivotContainer",
    class: ['p-table__container', !_vm.showHorizontalScrollbar ? 'no-scrolling-horizontal' : '', !_vm.showVerticalScrollbar ? 'no-scrolling-vertical' : ''],
    on: {
      "wheel": _vm.handleMouseWheel,
      "mouseover": _vm.blockBody,
      "mouseleave": _vm.unblockBody
    }
  }, [_vm.pivotItemsLength ? [_vm.showVerticalScrollbar ? _c('div', {
    staticClass: "p-table__scrollbar p-table__scrollbar--vertical",
    on: {
      "mousedown": function mousedown($event) {
        return _vm.handleScrollDragMouseDown('vertical', $event);
      }
    }
  }, [_c('div', {
    ref: "pScrollbarVertical",
    staticClass: "p-table__scrollbar-el",
    style: "top: ".concat(_vm.verticalScrollPosition, "; height: ").concat(_vm.verticalScrollbarHeight, "px !important;")
  }, [_c('div', {
    staticClass: "p-table__scrollbar-el-mask"
  })])]) : _vm._e(), _vm.showHorizontalScrollbar ? _c('div', {
    staticClass: "p-table__scrollbar p-table__scrollbar--horizontal",
    on: {
      "mousedown": function mousedown($event) {
        return _vm.handleScrollDragMouseDown('horizontal', $event);
      }
    }
  }, [_c('div', {
    ref: "pScrollbarHorizontal",
    staticClass: "p-table__scrollbar-el",
    style: "left: ".concat(_vm.horizontalScrollPosition, "; width: ").concat(_vm.horizontalScrollbarWidth, "px !important;")
  }, [_c('div', {
    staticClass: "p-table__scrollbar-el-mask"
  })])]) : _vm._e(), _c('div', {
    class: ['p-table__scroll-arrow p-table__scroll-arrow--prev', _vm.showHorizontalScrollbar ? 'padding-y' : '', !_vm.showPrevScrollingArrow ? 'hidden' : '']
  }, [_c('div', {
    staticClass: "p-table__scroll-arrow-btn",
    on: {
      "click": function click($event) {
        return _vm.handleScrollClick('prev');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": ""
    }
  })])]), _c('div', {
    class: ['p-table__scroll-arrow p-table__scroll-arrow--next', _vm.showHorizontalScrollbar ? 'padding-y' : '', _vm.showVerticalScrollbar ? 'padding-r' : '', !_vm.showNextScrollingArrow ? 'hidden' : '']
  }, [_c('div', {
    staticClass: "p-table__scroll-arrow-btn",
    on: {
      "click": function click($event) {
        return _vm.handleScrollClick('next');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": ""
    }
  })])]), _c('div', {
    ref: "pTableInner",
    staticClass: "p-table__inner",
    on: {
      "scroll": _vm.handleScroll,
      "mousemove": _vm.handleScrollDrag
    }
  }, [_c('table', {
    ref: "pTableEl",
    staticClass: "p-table__el"
  }, [_c('thead', [_c('tr', {
    staticClass: "p-table__col-numbers shifted"
  }, [_c('th', {
    ref: "th_row_groups",
    attrs: {
      "height": "30"
    },
    on: {
      "click": _vm.selectAll
    }
  }), _vm._l(_vm.pivotColumns, function (col, index) {
    var _vm$pivotData9, _vm$pivotData9$values, _vm$resize2;

    return _c('th', {
      key: "th-".concat(col.id, "_").concat(index + _vm.scrollPositionX + 1),
      ref: "th_".concat(col.id),
      refInFor: true,
      class: [_vm.isColSelected(col.id === 'rowGroup' ? index : index + _vm.scrollPositionX) ? 'selected' : '', col.id === 'rowGroup' && (_vm$pivotData9 = _vm.pivotData) !== null && _vm$pivotData9 !== void 0 && (_vm$pivotData9$values = _vm$pivotData9.values) !== null && _vm$pivotData9$values !== void 0 && _vm$pivotData9$values.length ? 'bordered' : ''],
      style: _vm.getColWidth(col.id),
      attrs: {
        "height": "30"
      },
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingCols($event, index + _vm.scrollPositionX);
        },
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleCols($event, index + _vm.scrollPositionX);
        },
        "mouseup": _vm.stopSelectingCols
      }
    }, [_c('span', [col.id === 'rowGroup' ? [_vm._v(" 1 ")] : [_vm._v(" " + _vm._s(index + _vm.scrollPositionX + 1) + " ")]], 2), _c('span', {
      class: ['p-table__drag-handle', ((_vm$resize2 = _vm.resize) === null || _vm$resize2 === void 0 ? void 0 : _vm$resize2.column) === col.id ? 'visible' : ''],
      on: {
        "mousedown": function mousedown($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.handleResizeMouseDown(col.id);
        }
      }
    })]);
  })], 2)]), _c('tbody', [_vm._l(_vm.formattedColumnRows, function (row, index) {
    var _vm$formattedColumnRo, _vm$formattedColumnRo2;

    return _c('tr', {
      key: "formatted-col-header-".concat(index),
      staticClass: "p-table__col-headers",
      style: "top: ".concat(30 * (index + 1), "px;")
    }, [_c('td', {
      class: ['p-table__row-number', _vm.isRowSelected("rowHead_".concat(index)) ? 'selected' : ''],
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingRows($event, "rowHead_".concat(index));
        },
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleRows($event, "rowHead_".concat(index));
        },
        "mouseup": _vm.stopSelectingRows
      }
    }), index === 0 ? _c('td', {
      class: ['text-left', _vm.selections.includes("rowHead_".concat(index, "_0")) ? 'selected' : ''],
      style: _vm.getColWidth('rowGroup'),
      attrs: {
        "data-id": "rowHead_".concat(index, "_0")
      },
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingCells($event, "rowHead_".concat(index), 0);
        },
        "mouseup": _vm.stopSelectingCells,
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleCells($event, "rowHead_".concat(index), 0);
        }
      }
    }, [_c('div', {
      class: ['p-table__row-icon', _vm.allRowsOpened ? 'reverse' : ''],
      on: {
        "click": _vm.toggleAllDetails,
        "mousedown": function mousedown($event) {
          $event.stopPropagation();
        }
      }
    }), _vm._v(" " + _vm._s(_vm.selectedRowNames) + " ")]) : _c('td', {
      class: ['text-left', _vm.selections.includes("rowHead_".concat(index, "_0")) ? 'selected' : '', index + 1 === ((_vm$formattedColumnRo = _vm.formattedColumnRows) === null || _vm$formattedColumnRo === void 0 ? void 0 : _vm$formattedColumnRo.length) ? 'total' : ''],
      style: _vm.getColWidth('rowGroup'),
      attrs: {
        "data-id": "rowHead_".concat(index, "_0")
      },
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingCells($event, "rowHead_".concat(index), 0);
        },
        "mouseup": _vm.stopSelectingCells,
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleCells($event, "rowHead_".concat(index), 0);
        }
      }
    }, [index + 1 === ((_vm$formattedColumnRo2 = _vm.formattedColumnRows) === null || _vm$formattedColumnRo2 === void 0 ? void 0 : _vm$formattedColumnRo2.length) ? _c('span', [_vm._v("Totals:")]) : _vm._e()]), _vm._l(_vm.formattedColumnRows[index], function (col, i) {
      return _c('td', {
        key: "formatted-col-subheader-".concat(index, "-").concat(i),
        class: ['remove-padding', !(col !== null && col !== void 0 && col.columnName) ? 'sticky-left' : 'text-left', _vm.selections.includes("rowHead_".concat(index, "_").concat(i + _vm.scrollPositionX + 1)) ? 'selected' : '', col !== null && col !== void 0 && col.abstract ? 'abstract' : ''],
        staticStyle: {
          "font-weight": "600"
        },
        style: _vm.getColWidth(col.id),
        attrs: {
          "colspan": col.colspan,
          "data-id": "rowHead_".concat(index, "_").concat(i + 1)
        },
        on: {
          "mousedown": function mousedown($event) {
            return _vm.startSelectingCells($event, "rowHead_".concat(index), i + _vm.scrollPositionX + 1);
          },
          "mouseup": _vm.stopSelectingCells,
          "mouseenter": function mouseenter($event) {
            return _vm.selectMultipleCells($event, "rowHead_".concat(index), i + _vm.scrollPositionX + 1);
          }
        }
      }, [!(col !== null && col !== void 0 && col.columnName) ? [_c('span', [_vm._v(_vm._s(_vm.getColLabel(col)))])] : _vm._e(), col !== null && col !== void 0 && col.columnName && !(col !== null && col !== void 0 && col.isTotal) ? [_c('span', [_vm._v(_vm._s(_vm.getValueLabel(_vm.pivotDataValueIds[(i + _vm.scrollPositionX) % _vm.pivotDataValueIds.length])))])] : _vm._e(), col !== null && col !== void 0 && col.columnName && col !== null && col !== void 0 && col.isTotal ? [_c('span', [_vm._v(_vm._s(_vm.getColumnSummaryValue(col)))])] : _vm._e()], 2);
    })], 2);
  }), _vm._l(_vm.pivotRows, function (row, rowIndex) {
    return _c('tr', {
      key: "".concat(row.id, "-").concat(_vm.scrollPositionY + rowIndex + 2, "-").concat(_vm.reRender)
    }, [_c('td', {
      class: ['p-table__row-number', _vm.isRowSelected(_vm.scrollPositionY + rowIndex) ? 'selected' : ''],
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingRows($event, _vm.scrollPositionY + rowIndex);
        },
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleRows($event, _vm.scrollPositionY + rowIndex);
        },
        "mouseup": _vm.stopSelectingRows
      }
    }, [_vm._v(_vm._s(_vm.scrollPositionY + rowIndex + 1))]), _vm._l(_vm.pivotColumns, function (col, colIndex) {
      var _vm$pivotData10, _vm$pivotData10$rows, _vm$pivotData11, _vm$pivotData11$rows, _vm$pivotSelectedColu, _vm$pivotColumns$colI;

      return _c('td', {
        key: "tdrc-".concat(col.id),
        class: [_vm.selections.includes("".concat(_vm.scrollPositionY + rowIndex, "_").concat(col.id === 'rowGroup' ? colIndex : colIndex + _vm.scrollPositionX)) ? 'selected' : '', col.id === 'rowGroup' ? "p-table__row-btn nested-".concat(row.level <= 7 ? row.level : 7) : '', col.id === 'rowGroup' && row.level < ((_vm$pivotData10 = _vm.pivotData) === null || _vm$pivotData10 === void 0 ? void 0 : (_vm$pivotData10$rows = _vm$pivotData10.rows) === null || _vm$pivotData10$rows === void 0 ? void 0 : _vm$pivotData10$rows.length) ? 'enabled' : ''],
        style: _vm.getColWidth(col.id),
        attrs: {
          "data-id": "".concat(_vm.scrollPositionY + rowIndex, "_").concat(col.id === 'rowGroup' ? colIndex : colIndex + _vm.scrollPositionX)
        },
        on: {
          "mousedown": function mousedown($event) {
            return _vm.startSelectingCells($event, _vm.scrollPositionY + rowIndex, col.id === 'rowGroup' ? colIndex : colIndex + _vm.scrollPositionX);
          },
          "mouseup": _vm.stopSelectingCells,
          "mouseenter": function mouseenter($event) {
            return _vm.selectMultipleCells($event, _vm.scrollPositionY + rowIndex, col.id === 'rowGroup' ? colIndex : colIndex + _vm.scrollPositionX);
          }
        }
      }, [col.id === 'rowGroup' ? [_c('span', [_vm._v(_vm._s(_vm.displayCellValue(_vm.pivotDataRowIds[row.level - 1], row)))]), row.level < ((_vm$pivotData11 = _vm.pivotData) === null || _vm$pivotData11 === void 0 ? void 0 : (_vm$pivotData11$rows = _vm$pivotData11.rows) === null || _vm$pivotData11$rows === void 0 ? void 0 : _vm$pivotData11$rows.length) ? _c('div', {
        class: ['p-table__row-icon', row.detailsOpened ? 'reverse' : ''],
        on: {
          "mousedown": function mousedown($event) {
            $event.stopPropagation();
          },
          "click": function click($event) {
            col.id === 'rowGroup' ? _vm.toggleDetails(row) : false;
          }
        }
      }) : _vm._e()] : [(_vm$pivotSelectedColu = _vm.pivotSelectedColumns) !== null && _vm$pivotSelectedColu !== void 0 && _vm$pivotSelectedColu.length ? [_vm._v(" " + _vm._s((row === null || row === void 0 ? void 0 : row.aggregations[(_vm$pivotColumns$colI = _vm.pivotColumns[colIndex]) === null || _vm$pivotColumns$colI === void 0 ? void 0 : _vm$pivotColumns$colI.id]) || '') + " ")] : [_vm._v(" " + _vm._s((row === null || row === void 0 ? void 0 : row.aggregations[col.id]) || '') + " ")]]], 2);
    })], 2);
  }), _vm._l(_vm.missingRows, function (i) {
    return _c('tr', {
      key: "missing-row-".concat(i)
    }, [_c('td', {
      class: ['p-table__row-number', _vm.isRowSelected(i + _vm.pivotItemsLength + _vm.scrollPositionY + 1) ? 'selected' : ''],
      on: {
        "mousedown": function mousedown($event) {
          return _vm.startSelectingRows($event, i + _vm.pivotItemsLength + _vm.scrollPositionY + 1);
        },
        "mouseenter": function mouseenter($event) {
          return _vm.selectMultipleRows($event, i + _vm.pivotItemsLength + _vm.scrollPositionY + 1);
        },
        "mouseup": _vm.stopSelectingRows
      }
    }, [_vm._v(" " + _vm._s(i + _vm.pivotItemsLength + _vm.scrollPositionY) + " ")]), _vm._l(_vm.pivotColumns, function (col, colIndex) {
      var _vm$pivotRows, _vm$pivotRows2;

      return _c('td', {
        key: "tdrce-".concat(col.id),
        class: _vm.selections.includes("".concat(i + ((_vm$pivotRows = _vm.pivotRows) === null || _vm$pivotRows === void 0 ? void 0 : _vm$pivotRows.length) + _vm.scrollPositionY + 1, "_").concat(colIndex + _vm.scrollPositionX)) ? 'selected' : '',
        style: _vm.getColWidth(col.id),
        attrs: {
          "data-id": "".concat(_vm.scrollPositionY + i + ((_vm$pivotRows2 = _vm.pivotRows) === null || _vm$pivotRows2 === void 0 ? void 0 : _vm$pivotRows2.length) + 1, "_").concat(colIndex + _vm.scrollPositionX)
        },
        on: {
          "mousedown": function mousedown($event) {
            var _vm$pivotRows3;

            return _vm.startSelectingCells($event, _vm.scrollPositionY + i + ((_vm$pivotRows3 = _vm.pivotRows) === null || _vm$pivotRows3 === void 0 ? void 0 : _vm$pivotRows3.length) + 1, colIndex + _vm.scrollPositionX);
          },
          "mouseup": _vm.stopSelectingCells,
          "mouseenter": function mouseenter($event) {
            var _vm$pivotRows4;

            return _vm.selectMultipleCells($event, _vm.scrollPositionY + i + ((_vm$pivotRows4 = _vm.pivotRows) === null || _vm$pivotRows4 === void 0 ? void 0 : _vm$pivotRows4.length) + 1, colIndex + _vm.scrollPositionX);
          }
        }
      });
    })], 2);
  })], 2)])])] : [_c('div', {
    ref: "pTableInner",
    staticClass: "p-table__inner"
  }, [_c('div', {
    staticClass: "p-table__placeholder"
  }, [_c('div', {
    staticClass: "p-table__placeholder-item"
  }, [_c('div', {
    staticClass: "p-table__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('WWW'),
      "alt": ""
    }
  })]), _c('p', {
    staticClass: "p-table__placeholder-text mb-3"
  }, [_vm._v("No data to display")]), _c('sygni-link-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "arrowSide": "right"
    },
    on: {
      "click": _vm.toggleManagementMode
    }
  }, [_vm._v("Go to Manage data")])], 1)])])]], 2) : _vm._e(), _vm.selectedView === 'pivot' && (_vm$pivotData12 = _vm.pivotData) !== null && _vm$pivotData12 !== void 0 && _vm$pivotData12.managementMode ? _c('div', {
    staticClass: "p-table__container no-scrolling-vertical no-scrolling-horizontal"
  }, [_c('pivot-management', {
    ref: "pManagementPanel",
    attrs: {
      "filteredColumns": _vm.filteredColumns,
      "pivotData": _vm.pivotData
    },
    on: {
      "change": _vm.updatePivotData
    }
  })], 1) : _vm._e()]], 2), (_vm$pivotDataSchema14 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema14 !== void 0 && (_vm$pivotDataSchema15 = _vm$pivotDataSchema14.toolbar) !== null && _vm$pivotDataSchema15 !== void 0 && _vm$pivotDataSchema15.manageFields ? _c('pivot-table-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFieldsModal,
      expression: "showFieldsModal"
    }],
    attrs: {
      "header": "Fields",
      "description": "Define how your data should be presented",
      "columns": (_vm$pivotDataSchema16 = _vm.pivotDataSchema) === null || _vm$pivotDataSchema16 === void 0 ? void 0 : _vm$pivotDataSchema16.columns
    },
    on: {
      "close": _vm.closeFieldsModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$pivotDataSchema17, _vm$pivotDataSchema18, _vm$pivotDataSchema19;

        return [(_vm$pivotDataSchema17 = _vm.pivotDataSchema) !== null && _vm$pivotDataSchema17 !== void 0 && (_vm$pivotDataSchema18 = _vm$pivotDataSchema17.columns) !== null && _vm$pivotDataSchema18 !== void 0 && _vm$pivotDataSchema18.length ? _c('draggable', {
          staticClass: "pivot-table-modal__list",
          attrs: {
            "handle": ".pivot-table-modal__list-item-handle"
          },
          model: {
            value: _vm.pivotDataSchema.columns,
            callback: function callback($$v) {
              _vm.$set(_vm.pivotDataSchema, "columns", $$v);
            },
            expression: "pivotDataSchema.columns"
          }
        }, _vm._l((_vm$pivotDataSchema19 = _vm.pivotDataSchema) === null || _vm$pivotDataSchema19 === void 0 ? void 0 : _vm$pivotDataSchema19.columns, function (col) {
          return _c('div', {
            key: "option-".concat(col === null || col === void 0 ? void 0 : col.id),
            staticClass: "pivot-table-modal__list-item"
          }, [_c('sygni-checkbox', {
            staticClass: "secondary",
            attrs: {
              "label": col === null || col === void 0 ? void 0 : col.title
            },
            model: {
              value: col.show,
              callback: function callback($$v) {
                _vm.$set(col, "show", $$v);
              },
              expression: "col.show"
            }
          }), _c('div', {
            staticClass: "pivot-table-modal__list-item-handle"
          })], 1);
        }), 0) : _vm._e()];
      },
      proxy: true
    }], null, false, 1415747663)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }