export type ColumnType = 'text' | 'datetime' | 'number' | 'array' | 'boolean'

export type View = 'grid' | 'pivot'

export interface PTableColumn {
  id: string,
  title: string,
  colType: ColumnType,
  withTime?: boolean,
  opened?: boolean,
  format?: {
    value: string | boolean | null,
    options: any[],
  },
  show?: boolean,
  readOnly?: boolean,
  isTotal?: boolean,
  totalValue?: string | number
}

export interface PTableComponentSchema {
  columns: PTableColumn[],
  sorting: {
    order: 'asc' | 'desc',
    orderBy: string,
  },
  toolbar: {
    displaySelectionDetails: boolean,
    manageFields: boolean,
    exportToCSV: boolean,
  },
  view: {
    enabled: View[],
    type: View
  },
}

export interface PivotDataSchema { 
  rows: any[],
  columns: any[],
  values: any[],
  items: any[],
  managementMode: boolean,
  openedFilter?: string
}

export const defaultSchema: PTableComponentSchema = {
  columns: [
    {
      id: 'reportNumber',
      title: 'Report Number',
      colType: 'text',
      show: true,
    },
    {
      id: 'accountNumber',
      title: 'Account Number',
      colType: 'text',
      show: true,
    },
    {
      id: 'number',
      title: 'Number',
      colType: 'number',
      format: {
        value: null,
        options: [],
      },
      show: true,
    },
    {
      id: 'counterpartyType',
      title: 'Counterparty Type',
      colType: 'text',
      show: true,
    },
    {
      id: 'counterpartyName',
      title: 'Counterparty Name',
      colType: 'text',
      show: true,
    },
    {
      id: 'counterpartyAddress',
      title: 'Counterparty Address',
      colType: 'text',
      show: true,
    },
    {
      id: 'counterpartyAccountNumber',
      title: 'Counterparty Account Number',
      colType: 'text',
      show: true,
    },
    {
      id: 'transactionNumber',
      title: 'Transaction Number',
      colType: 'text',
      show: true,
    },
    {
      id: 'transactionDate',
      title: 'Transaction Date',
      colType: 'datetime',
      opened: false,
      format: {
        value: 'date',
        options: [
          {
            value: 'date',
            label: 'Full Date',
            key: 'transactionDate'
          },
          {
            value: 'year',
            label: 'Year',
            column: {
              id: 'transactionDateYear',
              title: 'Transaction Date Year',
              colType: 'number',
              format: {
                value: 'integer',
                options: [],
              },
              show: true,
            }
          },
          {
            value: 'month',
            label: 'Month',
            column: {
              id: 'transactionDateMonth',
              title: 'Transaction Date Month',
              colType: 'number',
              format: {
                value: 'leadingZero',
                options: [],
              },
              show: true,
            },
          }
        ]
      },
      show: true,
    },
    {
      id: 'transactionDateYear',
      title: 'Transaction Date Year',
      colType: 'number',
      format: {
        value: 'integer',
        options: [],
      },
      show: true,
      readOnly: true,
    },
    {
      id: 'transactionDateMonth',
      title: 'Transaction Date Month',
      colType: 'number',
      format: {
        value: 'leadingZero',
        options: [],
      },
      show: true,
      readOnly: true,
    },
    {
      id: 'transactionCcy',
      title: 'Transaction Currency',
      colType: 'text',
      show: true,
    },
    {
      id: 'transactionSettlementStatus',
      title: 'Transaction Status',
      colType: 'text',
      show: true,
    },
    {
      id: 'description',
      title: 'Description',
      colType: 'text',
      show: true,
    },
    {
      id: 'ibanFormat',
      title: 'IBAN',
      colType: 'text',
      show: true,
    },
    {
      id: 'inflowCcy',
      title: 'Inflow Currency',
      colType: 'number',
      format: {
        value: null,
        options: [],
      },
      show: true,
    },
    {
      id: 'inflowPLN',
      title: 'Inflow PLN',
      colType: 'number',
      format: {
        value: null,
        options: [],
      },
      show: true,
    },
    {
      id: 'outflowCcy',
      title: 'Outflow Currency',
      colType: 'number',
      format: {
        value: null,
        options: [],
      },
      show: true,
    },
    {
      id: 'outflowPLN',
      title: 'Outflow PLN',
      colType: 'number',
      format: {
        value: null,
        options: [],
      },
      show: true,
    },
  ],
  sorting: {
    order: 'desc',
    orderBy: null
  },
  toolbar: {
    displaySelectionDetails: true,
    manageFields: true,
    exportToCSV: true,
  },
  view: {
    enabled: ['grid', 'pivot'],
    type: 'grid'
  }
}

export const testData = {
  "Kontrahent": {
    "Środki pieniężne w drodze": {
      "RKB/1/2019/1006": [
        {
          "id": 1, 
          "name": "test"
        },
        {
          "id": 2, 
          "name": "test 2"
        }
      ],
      "RKB/1/2019/1007": [
        {
          "id": 3, 
          "name": "test 3"
        },
        {
          "id": 4, 
          "name": "test 4"
        }
      ]
    },
    "Środki": {
      "RKB/1/2020/1000": [
        {
          "id": 5, 
          "name": "test 5"
        },
        {
          "id": 6, 
          "name": "test 6"
        }
      ],
      "RKB/1/2020/1001": [
        {
          "id": 7, 
          "name": "test 7"
        },
        {
          "id": 8, 
          "name": "test 8"
        }
      ]
    }
  },
  "Drugi typ": {
    "Fundusze": {
      "RKB/1/2021/1000": [
        {
          "id": 9,
          "name": "test 9"
        }
      ]
    }
  }
}