




























import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import { PTableColumn } from './types';

Component.registerHooks(['validations'])
@Component({
  components: { SygniContainerTitle, SygniRectButton },
})
export default class PivotTableModal extends Vue {
  @Prop({ default: [] }) columns: PTableColumn[];
  @Prop() isLoading!: boolean;
  @Prop() description: string;
  @Prop() header: string;
  
  closeModal() {
    this.$emit('close');
  }
}
