var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pivot-container"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), !_vm.selectedReport ? _c('pivot-reports', {
    on: {
      "loadReport": _vm.loadReport
    }
  }) : _vm._e(), _vm.selectedReport ? _c('pivot-table', {
    attrs: {
      "schema": _vm.pivotSchema,
      "reportName": _vm.selectedReport,
      "tableData": _vm.tableData
    },
    on: {
      "goBack": _vm.goBackHandler
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }